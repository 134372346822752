import React from 'react';
import { Form, Row, Col, Card, Accordion, InputGroup } from 'react-bootstrap';

function ScreenDetail(props) {

    return (
        <Card>
            <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                Screen Details
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <Form.Group as={Row}>
                        <Form.Label column>Item Name</Form.Label>
                        <Col>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    name="itemName"
                                    onChange={props.onChange}
                                    value={props.itemName}
                                    required
                                    isInvalid={props.itemName === ""}
                                />
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column>Bulk Density</Form.Label>
                        <Col>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>T/m3</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    name="density"
                                    onChange={props.onChange}
                                    value={props.properties.density}
                                    required
                                    isInvalid={props.properties.density === "" || isNaN(props.properties.density)}
                                />
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column>Efficiency</Form.Label>
                        <Col>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>%</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    name="efficiency"
                                    onChange={props.onChange}
                                    value={props.properties.efficiency}
                                    isInvalid={props.properties.efficiency === "" || isNaN(props.properties.efficiency)}
                                    required
                                />
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column>TPH Feed</Form.Label>
                        <Col>
                            <Form.Control
                                type="text"
                                name="feed"
                                onChange={props.onChange}
                                value={props.properties.feed}
                                isInvalid={props.properties.feed === "" || isNaN(props.properties.feed)}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column>Material</Form.Label>
                        <Col>
                            <Form.Control
                                type="text"
                                placeholder="e.g. Stone"
                                name="material"
                                onChange={props.onChange}
                                value={props.properties.material}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col>
                            <Form.Check
                                label="Wet Screening"
                                name="wetScreening"
                                type="checkbox"
                                onChange={props.onChange}
                                checked={props.properties.wetScreening}
                            />
                        </Col>
                        <Col>
                            <select className="custom-select" name="wetCalcOption" onChange={props.onChange} value={props.properties.wetCalcOption}>
                                <option value="1">Maximum</option>
                                <option value="2">Average</option>
                                <option value="3">Minimum</option>
                            </select>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column>Moisture Content</Form.Label>
                        <Col>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>%</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    name="mosture"
                                    onChange={props.onChange}
                                    value={props.properties.mosture}
                                    isInvalid={props.properties.mosture === "" || isNaN(props.properties.mosture)}
                                    required
                                />
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column>Shape ({props.properties.shape}%)</Form.Label>
                        <Col>
                            <InputGroup>
                                <input
                                    type="range"
                                    name="shape"
                                    className="custom-range"
                                    min="50"
                                    max="120"
                                    onChange={props.onChange}
                                    value={props.properties.shape}
                                />
                            </InputGroup>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <div style={{ marginRight: "2em", paddingLeft: "15px", width: "110px" }}>Angle Type</div>

                        <div className="custom-control custom-radio" style={{ marginRight: "1em" }}>
                            <input
                                type="radio"
                                id="angleType1"
                                name="angleType"
                                className="custom-control-input"
                                onChange={props.onChange}
                                value="0"
                                checked={props.properties.angleType === "0"}
                            />
                            <label className="custom-control-label" htmlFor="angleType1">Inclined</label>
                        </div>
                        <div className="custom-control custom-radio">
                            <input type="radio"
                                id="angleType2"
                                name="angleType"
                                className="custom-control-input"
                                onChange={props.onChange}
                                value="1"
                                checked={props.properties.angleType === "1"}
                            />
                            <label className="custom-control-label" htmlFor="angleType2">Horizontal</label>
                        </div>
                    </Form.Group>



                    <Form.Group as={Row}>
                        <div style={{ marginRight: "2em", paddingLeft: "15px", width: "110px" }}>Calculation</div>

                        <div className="custom-control custom-radio" style={{ marginRight: "1em" }}>
                            <input
                                type="radio"
                                id="calcMethod1"
                                name="calcMethod"
                                className="custom-control-input"
                                onChange={props.onChange}
                                value="1"
                                checked={props.properties.calcMethod === "1"}
                            />
                            <label className="custom-control-label" htmlFor="calcMethod1">HRI</label>
                        </div>
                        <div className="custom-control custom-radio">
                            <input type="radio"
                                id="calcMethod2"
                                name="calcMethod"
                                className="custom-control-input"
                                onChange={props.onChange}
                                value="2"
                                checked={props.properties.calcMethod === "2"}
                            />
                            <label className="custom-control-label" htmlFor="calcMethod2">VSMA</label>
                        </div>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <div style={{ marginRight: "2em", paddingLeft: "15px", width: "110px" }}>Screen Type</div>

                        <div className="custom-control custom-radio" style={{ marginRight: "1em" }}>
                            <input
                                type="radio"
                                id="screenType1"
                                name="screenType"
                                className="custom-control-input"
                                onChange={props.onChange}
                                value="0"
                                checked={props.properties.screenType === "0"}
                            />
                            <label className="custom-control-label" htmlFor="screenType1">Standard</label>
                        </div>
                        <div className="custom-control custom-radio">
                            <input type="radio"
                                id="screenType2"
                                name="screenType"
                                className="custom-control-input"
                                onChange={props.onChange}
                                value="1"
                                checked={props.properties.screenType === "1"}
                            />
                            <label className="custom-control-label" htmlFor="screenType2">High Energy</label>
                        </div>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <div style={{ marginRight: "2em", paddingLeft: "15px", width: "110px" }}>Feed Type</div>

                        <div className="custom-control custom-radio" style={{ marginRight: "1em" }}>
                            <input
                                type="radio"
                                id="feedType1"
                                name="feedType"
                                className="custom-control-input"
                                onChange={props.onChange}
                                value="0"
                                checked={props.properties.feedType === "0"}
                            />
                            <label className="custom-control-label" htmlFor="feedType1">Standard</label>
                        </div>
                        <div className="custom-control custom-radio">
                            <input type="radio"
                                id="feedType2"
                                name="feedType"
                                className="custom-control-input"
                                onChange={props.onChange}
                                value="1"
                                checked={props.properties.feedType === "1"}
                            />
                            <label className="custom-control-label" htmlFor="feedType2">Recirculating</label>
                        </div>
                    </Form.Group>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}

export default ScreenDetail;