import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Quotes/actions';
import { Row, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import PriceList from './PriceList';

export class PricingAdmin extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            addingUser: false,
            resettingPassword: false,
            selectedUser: null
        };

        this.getItems();
    }

    getItems = async () => {
        await this.props.getQuoteItems().then(() => {
            this.setState({ items: this.props.quoteItems });
        });
    }

    onChange = (e) => {
        let newItems = Object.assign([], this.state.items);

        newItems.filter((i) => {
            if (i.name === e.target.name) {
                i.value = e.target.value;
            }
            return i;
        });

        this.setState({ items: newItems });

    }

    onChangeOrder = (e) => {
        let newItems = Object.assign([], this.state.items);

        var item;
        newItems.filter((i) => {
            if (i.name === e.target.name) {
                i.order = e.target.value;
                item = i;
            }
            return i;
        });

        this.setState({ items: newItems }, () => {
            this.props.updateQuoteItem({
                id: item.id,
                name: item.name,
                value: item.value,
                included: item.included,
                category: item.category,
                order: item.order
            }).then(() => { this.getItems(); });
        });

    }

    onChangeIncluded = (e) => {
        let newItems = Object.assign([], this.state.items);

        let target = e.target;
        newItems.filter((i) => {
            if (i.id === target.id) {
                i.included = e.target.value;
            }
            return i;
        });

        this.setState({ items: newItems }, () => {

            let item = newItems.filter(x => x.id === target.id)[0];

            this.props.updateQuoteItem({
                id: item.id,
                name: item.name,
                value: item.value,
                included: item.included,
                category: item.category,
                order: item.order
            }).then(() => { this.getItems(); });
        });
    }

    onSave = (id) => {

        let item = this.state.items.filter(x => x.id === id)[0];

        this.props.updateQuoteItem({
            id: item.id,
            name: item.name,
            value: item.value,
            included: item.included,
            category: item.category,
            order: item.order
        });

    }


    onRemove = (id) => {

    }

    render() {

        const items = this.state.items && this.state.items.filter(x => x.category === this.props.filterCategory);
        return (
            <React.Fragment>
                <Row style={{ marginBottom: '1em' }}>
                </Row>

                <PriceList
                    label={this.props.columnLabel}
                    quoteItems={items}
                    onChange={this.onChange}
                    onChangeIncluded={this.onChangeIncluded}
                    onChangeOrder={this.onChangeOrder}
                    onSave={this.onSave}
                    onRemove={(id) => { this.props.onRemovePrice(id); }}
                />

                <OverlayTrigger
                    placement="right"
                    overlay={
                        <Tooltip > Add an Option </Tooltip>
                    }
                >
                    <Button
                        style={{ marginBottom: '2em' }}
                        variant="primary"
                        onClick={() => { this.props.onAddPrice(); }}
                        title="+"
                    >
                        <i className="fas fa-plus" />
                    </Button>
                </OverlayTrigger>

                {this.props.onRemovePriceGroup ?
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip > Remove {this.props.filterCategory} </Tooltip>
                        }
                    >
                        <Button
                            style={{ marginLeft: '5px', marginBottom: '2em' }}
                            variant="outline-danger"
                            onClick={() => { this.props.onRemovePriceGroup(); }}
                            title="-"
                        >
                            <i className="fas fa-trash-alt" />
                        </Button>
                    </OverlayTrigger>
                    :
                    <OverlayTrigger
                        placement="left"
                        overlay={
                            <Tooltip> Cannot remove this category </Tooltip>
                        }
                    >
                        <span style={{ opacity: .5 }}>
                            <Button
                                style={{ marginLeft: '5px', marginBottom: '2em' }}
                                variant="outline-secondary"
                                disabled
                            >
                                <i className="fas fa-trash-alt" />
                            </Button>
                        </span>

                    </OverlayTrigger>
                }
            </React.Fragment>
        );
    }
}

export default connect(
    state => state.quotes,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(PricingAdmin);
