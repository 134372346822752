import Api from '../../Api';
import {
    RECEIVED_USERS,
    RECEIVED_ROLES,
    SAVING_USER_DATA
} from '../action-types';

export const actionCreators = {
    getUsers: () => async (dispatch) => {
        await Api.getUsers().then(async (response) => {
            var result = await response.json();

            if (result.ok) {
                dispatch({
                    type: RECEIVED_USERS,
                    users: result.users
                });
            }
        });
    },

    getRoles: () => async (dispatch, getState) => {
        await Api.getRoles().then(async (data) => {
            let roles = await data.json();
            dispatch({
                type: RECEIVED_ROLES, roles
            });

        });
    },

    addUser: (userModel) => async (dispatch) => {

        dispatch({ type: SAVING_USER_DATA, inProgress: true, complete: false });

        console.log(userModel);

        await Api.addUser(userModel).then(async (response) => {
            var result = await response.json();

            if (result.ok) {
                //then....
            }
        });

        dispatch({ type: SAVING_USER_DATA, inProgress: false, complete: true });

    },

    resetUserPassword: (passwordModel) => async (dispatch) => {

        dispatch({ type: SAVING_USER_DATA, inProgress: true, complete: false });
        await Api.resetUserPassword(passwordModel).then(async (response) => {
            var result = await response.json();

            if (result.ok) {
                //then....
            }
        });

        dispatch({ type: SAVING_USER_DATA, inProgress: false, complete: true });
    },

    resetUserForms: () => async (dispatch) => {
        dispatch({ type: SAVING_USER_DATA, inProgress: false, complete: false });
    }
}