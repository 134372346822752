import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loading from '../../Common/Loading';
import { actionCreators } from '../../store/Quotes/actions';


const RemovePriceGroupDialog = (props) => {

    const { onComplete, saveInProgress, saveComplete, removePriceGroupItem, resetPriceForms, category, id } = props;
    const [open, setOpen] = useState(true);

    const handleSave = () => {

        if (id) { //removing a specific item
            removePriceGroupItem({ id })
        } else removePriceGroupItem({ groupName: category[2] })

    }

    const getMessage = () => {
        if (id) return `Are you sure you want to remove this ${category[1]} from '${category[0]}'`;
        else return `Are you sure you want to remove '${category[0]}'`;
    }

    return (
        <Modal show={open}
            centered
            onExited={() => {
                if (onComplete) {
                    resetPriceForms();
                    onComplete();
                }
            }}
        >
            <Modal.Header>
                <Modal.Title>{"Remove " + (id ? category[1] : category[0])}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {saveComplete ?
                    <div style={{ textAlign: "center", padding: '3em' }}>
                        <h4> {(id ? category[1] : category[0])} was removed successfully.</h4>
                    </div>
                    : saveInProgress ?
                        <Loading loadMessage={"Removing price " + (category ? "" : "category")} />
                        :
                        <>
                            <p>
                                {getMessage()}
                            </p>
                            {!id && <small className="text-danger"><strong>Warning: </strong>This action will remove multiple items</small>}
                        </>


                }
            </Modal.Body>
            <Modal.Footer>
                {
                    saveInProgress || saveComplete ?
                        <React.Fragment>
                            <Button
                                variant="primary"
                                onClick={() => setOpen(false)}
                                disabled={saveInProgress}
                            >
                                Close
                            </Button>

                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Button variant="secondary" onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                            <Button
                                variant="danger"
                                onClick={handleSave}
                            >
                                Remove
                            </Button>

                        </React.Fragment>
                }
            </Modal.Footer>
        </Modal>
    );
}

export default connect(
    state => state.quotes,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(RemovePriceGroupDialog);