import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Screen/actions';
import ScreenDesigner from '../ScreenDesigner';
import { Tab, Tabs } from 'react-bootstrap';

class ScreensContainer extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            selectedIndex: 0
        };
    }

    handleDeleteScreen() {

        this.props.deleteScreen(this.state.selectedIndex)
            .then(() => this.props.onUpdate());

        let index = this.state.selectedIndex - 1;

        if (index < 0)
            index = 0;

        this.setState({ selectedIndex: index });
    }

    handleSelectTab(eventKey, event) {

        if (eventKey === "ADD_SCREEN") {                        

            this.props.addScreen()
                .then(() => this.props.onUpdate())
                .then(() => {
                    this.setState({ selectedIndex: this.props.totalNumberOfScreens -1 })
                });
            
        } else {
            this.setState({ selectedIndex: eventKey });
        }
    }

    render() {
        
        return (
            <div className="screens-container" >
                <Tabs activeKey={this.state.selectedIndex} onSelect={this.handleSelectTab.bind(this)}>

                    {this.props.screens.map((i, index) => {
                        return <Tab title={i.itemName} eventKey={index} key={"tab_" + index} />
                    })}

                    <Tab title="+" eventKey="ADD_SCREEN" />
                </Tabs>

                <ScreenDesigner
                    onUpdate={() => { this.props.onUpdate(); }}
                    onDelete={() => { this.handleDeleteScreen(); }}
                    key={this.state.selectedIndex}
                    index={this.state.selectedIndex}
                />
            </div>
        );        
    }
}

export default connect(
    state => state.screening,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ScreensContainer);