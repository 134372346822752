import React from 'react';
import { Row, Col, Button, Modal, InputGroup, Form } from 'react-bootstrap';

function LipWeightDialog(props) {

    const { bearingLife, decks } = props;

    return (
        <Modal show>
            <Modal.Header>
                <Modal.Title>Lip Properties</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Row}>
                    <Form.Label column >Lip Liner Material</Form.Label>
                    <Col>
                        <Form.Control as="select"
                            name="lipMaterial"
                            onChange={(e) => { props.onUpdate(e); }}
                            value={bearingLife.lipMaterial}
                        >
                            <option value="MildSteel">Mild Steel</option>
                            <option value="ARSteel">A. R. Steel</option>
                            <option value="Hardox400">Hardox 400</option>
                            <option value="Rubber">Rubber</option>
                            <option value="Polyurethane">Polyurethane</option>
                        </Form.Control>
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column={"auto"}>Lip Length{decks > 1 ? "s" : ""}</Form.Label>
                    <Col>
                        {bearingLife.lipLengths.map((length, index) => {
                            if (index < (decks)) return (
                                <InputGroup key={index} style={{ marginBottom: '5px' }}>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Deck {index + 1}</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>mm</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="number"
                                        name="lipLengths"
                                        onChange={(e) => { props.onUpdate(e, index); }}
                                        value={bearingLife.lipLengths[index]}
                                    />
                                </InputGroup>
                            );
                            return(null);
                    })}
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column>Lip Liner Depth</Form.Label>
                    <Col>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>mm</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="number"
                                name="lipLinerDepth"
                                onChange={(e) => { props.onUpdate(e); }}
                                value={bearingLife.lipLinerDepth}
                            />
                        </InputGroup>
                    </Col>
                </Form.Group>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default LipWeightDialog;