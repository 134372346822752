import React from 'react';
import { Route } from 'react-router';
import Layout from './Layout';
import Administration from './Administration';
import SignIn from './SignIn';
import PrivateRoute from './PrivateRoute';
import ScreenDesigner from './ScreenDesigner';
import Enquiry from './Enquiry';
import Enquiries from './Enquiries';

export default function App() {
    return (
        <Layout>
            <Route exact path='/' component={SignIn} />
            <PrivateRoute path='/designer' component={ScreenDesigner} />
            <PrivateRoute exact path='/enquiry' component={Enquiry} />
            <PrivateRoute exact path='/enquiry/:enquiryId' component={Enquiry} />

            <PrivateRoute path='/enquiries' component={Enquiries} />
            <PrivateRoute exact path='/admin' component={Administration} />
        </Layout>);
}
