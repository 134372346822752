import React from 'react';
import NavMenu from './NavMenu';

export default function Layout(props) {
    return (
        <React.Fragment>
            <NavMenu />
            {props.children}


        </React.Fragment>
    );
}
