import React from 'react';
import { Row, Col } from 'react-bootstrap';

function CutProperties({ properties, ...props }) {

    return (
        <div className="property-table">
            <Row>
                <Col className="property-title">Cut Properties</Col>
            </Row>
            <Row>
                <Col>Cut Size</Col>
                <Col>{properties.cutSize}mm</Col>
            </Row>
            <Row>
                <Col>TPH Feed</Col>
                <Col>{Number(properties.feed).toFixed(3)}</Col>
            </Row>
            <Row>
                <Col>Efficiency Of Removal</Col>
                <Col className={props.desiredEfficiency > parseInt(properties.efficiencyOfRemoval) ? "cut-warning" : ""}>{parseInt(properties.efficiencyOfRemoval)}%</Col>
            </Row>
            <Row>
                <Col>Contamination Of Overs</Col>
                <Col className={props.desiredEfficiency > parseInt(properties.efficiencyOfRemoval) ? "cut-warning" : ""}>{parseInt(properties.contaminationOfOvers)}%</Col>
            </Row>
            <Row>
                <Col>Moisture</Col>
                <Col>{properties.moisture}%</Col>
            </Row>
            <Row>
                <Col>Area</Col>
                <Col>{Number(properties.areaSQM).toFixed(3)} sq M / {Number(properties.area).toFixed(3)} sq ft</Col>
            </Row>
            <Row>
                <Col>Width</Col>
                <Col>
                    {properties.widthMM}mm / {properties.width}ft
                </Col>
            </Row>
            <Row>
                <Col>Length</Col>
                <Col>
                    {properties.lengthMM}mm / {properties.length}ft
                </Col>
            </Row>
            <Row>
                <Col>Ratio</Col>
                <Col>
                    {Number(properties.ratio).toFixed(2)}
                </Col>
            </Row>
            <Row>
                <Col>Normal Limit</Col>
                <Col>{Number(properties.normalLimit).toFixed(2)}</Col>
            </Row>
            <Row>
                <Col>Rate Of Travel (ft/min)</Col>
                <Col>{Number(properties.rot).toFixed(1)}</Col>
            </Row>
            <Row>
                <Col>Angle</Col>
                <Col>{Number(properties.angle).toFixed(3)}</Col>
            </Row>
            <Row>
                <Col>Bed Depth Feed End</Col>
                <Col>{Number(properties.bedDepthFeedEnd).toFixed(2)}mm</Col>
            </Row>
            <Row>
                <Col>Bed Depth Dischange</Col>
                <Col>{Number(properties.bedDepthDischarge).toFixed(2)}mm</Col>
            </Row>
            <Row>
                <Col>Avg Particle Over Deck</Col>
                <Col>{Number(properties.avgParticleOverDeck).toFixed(2)}mm</Col>
            </Row>
        </div>);
}

export default CutProperties;