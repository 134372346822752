import React from 'react';
import { Button, ButtonGroup, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { actionCreators } from '../store/Quotes/actions';
import { bindActionCreators } from 'redux';

class QuoteOption extends React.Component {

    constructor(props) {
        super(props);

        this.state = {


        }
    }

    componentDidMount() {
        this.props.doesQuoteExist(this.props.enquiryId);
    }

    handleDownload = () => {
        this.props.downloadQuote(this.props.enquiryId, this.props.enquiryNumber);
    };

    render() {

        return <ButtonGroup>
                <Button
                    variant="primary"
                    disabled={this.props.unsavedChanges || this.props.newEnquiry}
                    onClick={this.props.onGenerateQuote}
                >
                    New Quote
                </Button>
                <Button
                    variant="primary"
                    disabled={!this.props.quoteExists}
                    onClick={this.handleDownload}
                >
                    <i className="fas fa-download" />
                </Button>
            </ButtonGroup>;

    }

}


export default connect(
    state => state.quotes,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(QuoteOption)