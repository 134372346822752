import Api from "../../Api";
import { getDefaultScreen } from './reducer';
import { cloneDeep } from 'lodash';
import {
    UPDATE_SCREEN_OPTIONS,
    UPDATE_WATER_OPTIONS,
    UPDATE_BEARING_OPTIONS,
    UPDATE_DECK,
    UPDATE_BREAKDOWN,
    ADD_DECK,
    REMOVE_DECK,
    ADD_BREAKDOWN,
    REMOVE_BREAKDOWN,
    BUILDING_SCREEN,
    SCREEN_BUILT,
    BUILD_FAILED,
    UPDATE_DECK_CUT_PROP,
    RESET_DECK_CUT_PROP,
    SCREEN_DETAIL_INVALID,
    RESET_SCREEN,
    SCREEN_REBUILT,
    ADD_SCREEN,
    DELETE_SCREEN
} from '../action-types';


export const actionCreators = {

    addScreen: () => async (dispatch, getState) => {

        let screens = Object.assign([], getState().screening.screens);

        // Deep copy of default screen to prevent the usage of references
        let defaultScreen = cloneDeep(getDefaultScreen());

        screens.push(defaultScreen);

        console.log(defaultScreen);

        dispatch({
            type: ADD_SCREEN, screens
        });
    },
    deleteScreen: (index) => async (dispatch, getState) => {

        let screens = getState().screening.screens.filter((screen, i) => {
            if (i !== Number(index))
                return screen;
        })

        dispatch({
            type: DELETE_SCREEN, screens
        });
    },
    updateScreenProperites: (index, name, value) => async (dispatch, getState) => {

        var screens = Object.assign([], getState().screening.screens);

        if (name === "itemName") {
            screens[index].itemName = value;
        } else {
            screens[index].properties[name] = value;
        }

        screens[index].screenInvalidated = true;
        dispatch({
            type: UPDATE_SCREEN_OPTIONS, screens
        });
    },

    updateDeck: (index, deck, cut, value) => async (dispatch, getState) => {

        let screens = Object.assign([], getState().screening.screens);
        let decks = screens[index].decks;

        decks[deck].cuts[cut].Size = value;

        for (let i = 1; i < 3; i++) {
            if (decks[deck].cuts[i].Size !== 0) {
                decks[deck].cutInvalid[i] = parseInt(decks[deck].cuts[i].Size) <= parseInt(decks[deck].cuts[i - 1].Size);
                if (decks[deck].cuts[i].Size === 0) {
                    decks[deck].cutInvalid[i] = false;
                }
            }
            if (decks[deck].cuts[0].Size <= 0) {
                decks[deck].cutInvalid[0] = true;
            }
            else
                decks[deck].cutInvalid[0] = false;
        }

        if (decks.length > 1) {
            for (let i = 1; i < decks.length; i++) {
                decks[i].cutInvalid[0] = parseInt(decks[i].cuts[0].Size) >= parseInt(decks[i - 1].cuts[0].Size);
            }
        }

        screens[index].decks = decks;

        dispatch({
            type: UPDATE_SCREEN_OPTIONS, screens
        });
    },
    addNewDeck: (index) => async (dispatch, getState) => {

        let screens = Object.assign([], getState().screening.screens);
        let decks = screens[index].decks;

        decks[decks.length] = {
            cuts: [{ Size: 0 }, { Size: 0 }, { Size: 0 }],
            cutInvalid: [false, false, false]
        };

        dispatch({
            type: UPDATE_SCREEN_OPTIONS, screens
        });
    },
    removeDeck: (index, dIndex) => async (dispatch, getState) => {

        let screens = Object.assign([], getState().screening.screens);
        let decks = screens[index].decks.filter((value, i) => {
            return i !== dIndex;
        });

        screens[index].decks = decks;

        dispatch({
            type: UPDATE_SCREEN_OPTIONS, screens
        });
    },
    updateDeckMaterial: (index, deck, value) => async (dispatch, getState) => {

        let screens = Object.assign([], getState().screening.screens);
        let decks = screens[index].decks;

        decks[deck].material = value;

        dispatch({
            type: UPDATE_SCREEN_OPTIONS, screens
        });
    },

    updateBreakdown: (index, line, name, value) => async (dispatch, getState) => {

        let screens = Object.assign([], getState().screening.screens);
        let breakdown = screens[index].breakdown;

        breakdown[line][name] = value;

        for (let i = 0; i < breakdown.length; i++) {
            if (i > 0) {
                breakdown[i]["mmInvalid"] = Number(breakdown[i]["mm"]) >= Number(breakdown[i - 1]["mm"]);
                breakdown[i]["passingInvalid"] = Number(breakdown[i]["passing"]) >= Number(breakdown[i - 1]["passing"]);
            } else {
                breakdown[0]["passingInvalid"] = Number(breakdown[0]["passing"]) > 100;
            }
        }

        dispatch({
            type: UPDATE_SCREEN_OPTIONS, screens
        });
    },
    addBreakdownLine: (index) => async (dispatch, getState) => {

        let screens = Object.assign([], getState().screening.screens);
        let breakdown = screens[index].breakdown;
        breakdown[breakdown.length] = { passing: 0, mm: 0 };

        dispatch({
            type: UPDATE_SCREEN_OPTIONS, screens
        });
    },
    removeBreakdownLine: (index, bIndex) => async (dispatch, getState) => {

        let screens = Object.assign([], getState().screening.screens);
        let breakdown = screens[index].breakdown.filter((value, i) => {
            return i !== bIndex;
        });
        screens[index].breakdown = breakdown;

        dispatch({
            type: UPDATE_SCREEN_OPTIONS, screens
        });
    },

    updateCutProps: (index, deck, cut, properties) => async (dispatch, getState) => {

        var screens = Object.assign([], getState().screening.screens);

        for (let prop in properties) {
            if (properties[prop] !== null)
                screens[index].decks[deck].cuts[cut][prop] = properties[prop];
        }

        dispatch({
            type: UPDATE_SCREEN_OPTIONS, screens
        });
    },
    resetCutProps: (index, deck, cut) => async (dispatch, getState) => {

        var screens = Object.assign([], getState().screening.screens);
        screens[index].decks[deck].cuts[cut] = { Size: screens[index].decks[deck].cuts[cut].Size };

        dispatch({
            type: UPDATE_SCREEN_OPTIONS, screens
        });
    },

    build: (index) => async (dispatch, getState) => {

        var screens = Object.assign([], getState().screening.screens);
        let decks = screens[index].decks;
        let cutValidation = decks.filter(m => m.cutInvalid.filter(cutValidation => cutValidation).length !== 0); // check if decks are valid

        // Get the form using the dom, so we can use the native check validity
        if (!document.theForm.checkValidity() || cutValidation.length > 0) {
            dispatch({ type: SCREEN_DETAIL_INVALID });
        } else {

            var screen = screens[index];

            if (screen.calculatedScreen) {
                screen.waterProperties = waterPropsModel(screen.calculatedScreen.waterRequirements);
                screen.bearingProperties = bearingPropsModel(screen.calculatedScreen.bearingLife);
            }

            dispatch({ type: BUILDING_SCREEN });

            await Api.Screen.calculateScreen(screen).then(async (response) => {

                var result = await response.json();

                if (result.ok) {
                    screen.calculatedScreen = result.screen;
                    screen.screenInvalidated = false;

                    screens[index] = screen;
                    dispatch({ type: SCREEN_BUILT, screens });

                } else {
                    dispatch({ type: BUILD_FAILED, result });
                }
            });
        }
    },
    rebuildScreen: (index) => async (dispatch, getState) => {

        dispatch({ type: BUILDING_SCREEN });

        await Api.Screen.calculateScreen(getState().screening.screens[index]).then(async (response) => {

            var result = await response.json();

            if (result.ok) {

                let screens = Object.assign([], getState().screening.screens);

                if (screens[index].properties.UserDefinedWidth != null) screens[index].properties.UserDefinedWidth = result.screen.widthMM;
                if (screens[index].properties.UserDefinedLength != null) screens[index].properties.UserDefinedLength = result.screen.lengthMM;
                if (screens[index].properties.UserDefinedAngle != null) screens[index].properties.UserDefinedAngle = result.screen.angle;
              //  if (screens[index].properties.UserDefinedNormalLimit != null) screens[index].properties.UserDefinedNormalLimit = result.screen.normalLimit;

                screens[index].calculatedScreen = result.screen;
                screens[index].screenInvalidated = false;
                dispatch({ type: SCREEN_BUILT, screens: screens });

            } else {
                dispatch({ type: BUILD_FAILED, result });
            }
        });
    },
    resetScreen: (index) => async (dispatch, getState) => {

        let screens = Object.assign([], getState().screening.screens);

        delete screens[index].properties.UserDefinedLength;
        delete screens[index].properties.UserDefinedWidth;
        delete screens[index].properties.UserDefinedAngle;
        delete screens[index].properties.UserDefinedRatio;    
        delete screens[index].properties.calculateEfficiency;
        delete screens[index].properties.increaseCapacity;

        for (var i = 0; i < screens[index].decks.length; i++) {
            for (var j = 0; j < screens[index].decks[i].cuts.length; j++) {
                delete screens[index].decks[i].cuts[j].openAreaActual;
                delete screens[index].decks[i].cuts[j].slot;
                delete screens[index].decks[i].cuts[j].openAreaFactor;
                delete screens[index].decks[i].cuts[j].efficiency;
                delete screens[index].decks[i].cuts[j].normalLimit;
                delete screens[index].decks[i].cuts[j].ratio;
            }
        }

        dispatch({ type: RESET_SCREEN, screens: screens });
        dispatch({ type: BUILDING_SCREEN });

        await Api.Screen.calculateScreen(screens[index]).then(async (response) => {

            var result = await response.json();

            if (result.ok) {

                screens[index].calculatedScreen = result.screen;
                screens[index].screenInvalidated = false;
                dispatch({ type: SCREEN_BUILT, screens: screens });
            } else {
                dispatch({ type: BUILD_FAILED, result });
            }
        });
    },
    calculateEfficiency: (index) => async (dispatch, getState) => {

        let props = getState().screening.screens[index].properties;

        let width = (props.UserDefinedWidth != null) ? props.UserDefinedWidth : getState().screening.screens[index].calculatedScreen.widthFT;
        let length = (props.UserDefinedLength != null) ? props.UserDefinedLength : getState().screening.screens[index].calculatedScreen.lengthFT;

        dispatch(actionCreators.updateScreenProperites(index, "UserDefinedWidth", width));
        dispatch(actionCreators.updateScreenProperites(index, "UserDefinedLength", length));

        dispatch(actionCreators.updateScreenProperites(index, "calculateEfficiency", true))
            .then(() => dispatch(actionCreators.updateScreenProperites(index, "increaseCapacity", false)))
            .then(() => dispatch(actionCreators.rebuildScreen(index)));
    },
    increaseCapacity: (index) => async (dispatch, getState) => {

        let props = getState().screening.screens[index].properties;

        let width = (props.UserDefinedWidth != null) ? props.UserDefinedWidth : getState().screening.screens[index].calculatedScreen.widthFT;
        let length = (props.UserDefinedLength != null) ? props.UserDefinedLength : getState().screening.screens[index].calculatedScreen.lengthFT;

        dispatch(actionCreators.updateScreenProperites(index, "UserDefinedWidth", width));
        dispatch(actionCreators.updateScreenProperites(index, "UserDefinedLength", length));

        dispatch(actionCreators.updateScreenProperites(index, "calculateEfficiency", false))
            .then(() => dispatch(actionCreators.updateScreenProperites(index, "increaseCapacity", true)))
            .then(() => dispatch(actionCreators.rebuildScreen(index)));
    },

    updateWaterProperites: (screenIndex, name, value) => async (dispatch, getState) => {

        var currentWaterRequirements = Object.assign({}, getState().screening.screens[screenIndex].calculatedScreen.waterRequirements);
        var screens = Object.assign([], getState().screening.screens);
        var properties = waterPropsModel(currentWaterRequirements);

        //update inputs bound to state
        screens[screenIndex].calculatedScreen.waterRequirements = currentWaterRequirements;

        if (properties[name].toString() !== value) {

            properties[name] = value;
            screens[screenIndex].waterProperties = properties;
            screens[screenIndex].rebuild = true;

            dispatch({
                type: UPDATE_WATER_OPTIONS, screens, rebuild: true
            });
        }

    },
    updateBearingProperites: (screenIndex, name, value, index) => async (dispatch, getState) => {

        var rebuild = false;

        var currentBearingLife = Object.assign({}, getState().screening.screens[screenIndex].calculatedScreen.bearingLife);
        var screens = Object.assign([], getState().screening.screens);

        //only update the value if it has changed.
        if (index >= 0) {
            if (currentBearingLife[name][index].toString() !== value.toString()) {
                currentBearingLife[name][index] = value;
                rebuild = true;
            }
        } else {
            if (currentBearingLife[name].toString() !== value.toString()) {
                currentBearingLife[name] = value;
                rebuild = true;
            }
        }

        //build up the properties for calculating bearing life
        var properties = bearingPropsModel(currentBearingLife);

        //update inputs bound to state
        screens[screenIndex].calculatedScreen.bearingLife = currentBearingLife;
        screens[screenIndex].bearingProperties = properties;
        dispatch({
            type: UPDATE_BEARING_OPTIONS, screens, rebuild
        });
    }
};

const waterPropsModel = (current) => {
    if (!current) return null;
    var properties = {
        nozzleDiameter: current.nozzleDiameter,
        waterInFeedM3H: current.m3HInFeed,
        waterPressure: current.systemPressure
    };

    return properties;
};

const bearingPropsModel = (current) => {
    if (!current) return null;
    var properties = {
        vibratedWeight: current.vibratedWeight,
        otherWeight: current.otherWeight,
        g: current.g,
        selectedSpeed: current.selectedSpeed,
        selectedStroke: current.selectedStroke,
        vibratorUnit: current.vibratorUnit,
        vibratorQuantity: current.vibratorQuantity,

        feedboxLength: current.feedboxLength,
        feedboxMaterial: current.feedboxMaterial,
        feedboxLinerDepth: current.feedboxLinerDepth,

        lipLengths: current.lipLengths,
        lipMaterial: current.lipMaterial,
        lipLinerDepth: current.lipLinerDepth
    };

    return properties;
};