import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Users/actions';

export class SaveEnquiryDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: true
        };
    }

    handleSave = () => {
        this.setState({ open: false },
            this.props.onSave()
        );
    }

    handleClose = () => {
        this.setState({ open: false },
            this.props.onClose()
        );
    }

    cancel = () => {
        this.setState({ open: false }
        );
    }

    render() {

        const { open } = this.state;

        return (
            <Modal show={open}
                centered
                onExited={() => {
                    if (this.props.onComplete)
                        this.props.onComplete();
                }}
            >
                <Modal.Header>
                    <Modal.Title>Save this enquiry?</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form
                        onSubmit={this.handleSave}
                    >

                        <Form.Group >
                            <Form.Label>Enquiry Number <small>(required)</small></Form.Label>
                            <Form.Control
                                name="enquiryNumber"
                                value={this.props.enquiryNumber}
                                onChange={this.props.onUpdate}
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Customer Name</Form.Label>
                            <Form.Control
                                name="customerName"
                                value={this.props.customerName}
                                onChange={this.props.onUpdate}
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Item Name</Form.Label>
                            <Form.Control
                                name="itemName"
                                value={this.props.itemName}
                                onChange={this.props.onUpdate}
                            />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={this.props.enquiryNumber.length < 1}
                        variant="primary"
                        onClick={this.handleSave}
                    >
                        Save
                </Button>
                    {this.props.onClose &&
                        <Button
                            variant="secondary"
                            onClick={this.handleClose}
                        >
                            Don't Save
                </Button>
                    }

                    <Button
                        variant="secondary"
                        onClick={this.cancel}
                    >
                        Cancel
                </Button>


                </Modal.Footer>
            </Modal >
        );
    }


}

export default connect(
    state => state.users,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SaveEnquiryDialog);