import React from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Users/actions';
import UserAdmin from '../Users';
import  PricingGroups  from './PricingGroups';

class Administration extends React.Component {


    componentDidMount() {
        if (!this.props.userIsAdmin)
            window.location.href = "/";
    }

    render() {

        return (
            <Container className="page-container">
                <Tabs defaultActiveKey="users" className="administration-tabs" justify>
                    <Tab eventKey="users" title="Users">
                        <UserAdmin />
                    </Tab>
                    <Tab eventKey="groups" title="Pricing">
                        <PricingGroups />
                    </Tab>
                </Tabs>
            </Container >
        );
    }
}

export default connect(
    state => state.users,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Administration);
