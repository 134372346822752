import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../store/Screen/actions';
import { Row, Col, Table, Form, InputGroup, Alert } from 'react-bootstrap';

function WaterRequirements(props) {

    const { waterRequirements } = props;

    const onUpdate = (e) => {
        props.updateWaterProperites(props.selectedScreenIndex, e.target.name, e.target.value);
    };

    const onUpdated = (e) => {        
        if (props.rebuild)
            props.rebuildScreen(props.selectedScreenIndex).then(() => {
                props.onUpdate();
            });
    };

    const buildTable = () => {
        let tableRows = [];
        for (var i = 0; i < waterRequirements.decks; i++) {
            tableRows.push(
                <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{Math.round(waterRequirements.requiredPerDeck[i])}</td>
                    <td>{waterRequirements.pipesPerDeck[i]} </td>
                    <td>{waterRequirements.nozzlesPerDeck[i]} </td>
                    <td>{Math.round(waterRequirements.deliveredPerDeck[i])}</td>
                </tr>
            );
        }
        tableRows.push(
            <tr key="total">
                <td /><td /><td /><td />
                <td style={{ fontWeight: 'bold' }}>{waterRequirements.deliveredPerDeck.reduce((a, b) => a + Math.round(b), 0)}</td>
            </tr>
        );
        return tableRows;
    };

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Form.Group as={Row}>
                        <Form.Label column> Water Required  </Form.Label>
                        <Col>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>m³/h</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="number"
                                    name="m3HRequired"
                                    value={Math.round(waterRequirements.m3HRequired)}
                                    readOnly
                                />
                            </InputGroup>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column > Water In Feed </Form.Label>
                        <Col>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>m³/h</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    name="waterInFeedM3H"
                                    onChange={(e) => { onUpdate(e); }}
                                    onBlur={(e) => { onUpdated(e); }}
                                    min='0'
                                    type="number"
                                    defaultValue={Math.round(waterRequirements.m3HInFeed)}
                                />
                            </InputGroup>
                        </Col>
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group as={Row}>
                        <Form.Label column> System Pressure </Form.Label>
                        <Col>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>psi</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    name="waterPressure"
                                    onChange={(e) => { onUpdate(e); }}
                                    onBlur={(e) => { onUpdated(e); }}
                                    min='0'
                                    type="number"
                                    defaultValue={waterRequirements.systemPressure}
                                />
                            </InputGroup>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column> Nozzle Diameter  </Form.Label>
                        <Col>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>mm</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    name="nozzleDiameter"
                                    onChange={(e) => { onUpdate(e); }}
                                    onBlur={(e) => { onUpdated(e); }}
                                    min='0'
                                    type="number"
                                    defaultValue={Math.round(waterRequirements.nozzleDiameter)}
                                />
                            </InputGroup>

                        </Col>
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group as={Row}>
                        <Form.Label column > Water By Spray </Form.Label>
                        <Col>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>m³/h</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="number"
                                    name="m3HBySpray"
                                    value={Math.round(waterRequirements.m3HBySpray)}
                                    readOnly
                                    disabled
                                />
                            </InputGroup>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column> Water By Nozzle </Form.Label>
                        <Col>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>m³/h</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="number"
                                    name="m3HPerNozzle"
                                    value={(Math.round(waterRequirements.m3HPerNozzle * 100) / 100)}
                                    readOnly
                                    disabled
                                />
                            </InputGroup>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>

            {!waterRequirements.isValidForLength &&
                <Row>
                    <Col>
                        <Alert variant="danger">
                            <small>Spray bars will not fit on screen. Try increasing the System Pressure or Nozzle Diameter</small>
                        </Alert>
                    </Col>
                </Row>
            }

            <Row>
                <Col>
                    <Table striped borderless hover size="sm">
                        <thead>
                            <tr>
                                <th>Deck</th>
                                <th>Required Water <i className="fas fa-tint" style={{ marginLeft: '.5em' }} /></th>
                                <th>Pipes <i className="fas fa-grip-lines-vertical" style={{ marginLeft: '.5em' }} /></th>
                                <th>Nozzles <i className="fas fa-shower" style={{ marginLeft: '.5em' }} /></th>
                                <th>Delivered Water <i className="fas fa-water" style={{ marginLeft: '.5em' }} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {buildTable()}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default connect(
    state => state.screening,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(WaterRequirements);