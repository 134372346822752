import React from 'react';
import { Form, Row, Col, Card, Button, Accordion } from 'react-bootstrap';

function Breakdown(props) {

    return (
        <Card>
            <Accordion.Toggle as={Card.Header} variant="link" eventKey="2">
                Grading
                </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
                <>
                    <Card.Body>
                        <Row>
                            <Col md="5">
                                MM
                    </Col>
                            <Col md="5">
                                Passing
                    </Col>
                        </Row>
                        {props.breakdown.map(function (line, index) {
                            return (
                                <Row className="deck-item" key={index}>
                                    <Col md="5">
                                        <Form.Control
                                            autoComplete="off"
                                            type="text"
                                            name="mm"
                                            value={line.mm}
                                            isInvalid={line.mmInvalid || isNaN(line.mm)}
                                            onChange={props.onChange.bind(null, index)}
                                            required
                                        />
                                    </Col>
                                    <Col md="5">
                                        <Form.Control
                                            autoComplete="off"
                                            type="text"
                                            name="passing"
                                            value={line.passing}
                                            isInvalid={line.passingInvalid || isNaN(line.passing)}
                                            onChange={props.onChange.bind(null, index)}
                                            required
                                        />
                                    </Col>
                                    <Col md="1">
                                        <Button onClick={props.onDeleteItem.bind(null, index)}>
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    </Col>
                                </Row>);
                        })}
                    </Card.Body>
                    <Card.Footer>
                        <Button onClick={props.onNewItem}>Add Breakdown</Button>
                    </Card.Footer>
                </>
            </Accordion.Collapse>
        </Card>
    );
}

export default Breakdown;