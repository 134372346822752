import React, { useState } from 'react';
import { Navbar, Button, ButtonToolbar, Form, InputGroup, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { actionCreators } from '../store/Screen/actions';
import { bindActionCreators } from 'redux';
import EnquiryToolbarQuoteOption from './EnquiryToolbarQuoteOption';
import { cloneDeep } from 'lodash';

function Toolbar(props) {

    const [showDialog, setShowDialog] = useState(false);

    let saveComplete = (props.newEnquiry === false && props.unsavedChanges === false) ? true : false;
    let saveButtonDisabled = saveComplete || props.enquiryNumber.length < 1;
    let invalidChars = `\/:*?"<>|`;
    let sanitize = (e) => {

        //exclude invalid characters to ensure filenames are safe;
        let originalValue = cloneDeep(e.target.value);
        e.target.value = e.target.value.replace(/[\x00-\x1F\\/:*?"<>|]/g, '');

        if (e.target.value !== originalValue)
            setShowDialog(true);

        return e;
    }

    return (
        <Navbar className="bg-light justify-content-between">
            <Form inline>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>Enquiry #</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        name="enquiryNumber"
                        value={props.enquiryNumber}
                        onChange={(e) => { props.onUpdate(sanitize(e)) }}
                    />
                </InputGroup>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>Customer</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        name="customerName"
                        value={props.customerName}
                        onChange={props.onUpdate}
                    />
                </InputGroup>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>Contact</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        name="contactName"
                        value={props.contactName}
                        onChange={props.onUpdate}
                    />
                </InputGroup>

            </Form>
            <ButtonToolbar>
                <Button
                    variant={saveButtonDisabled ? "outline-primary" : "primary"}
                    onClick={props.onSave}
                    disabled={saveButtonDisabled}
                >{saveComplete ? "Saved!" : "Save"}
                </Button>

                <EnquiryToolbarQuoteOption
                    {...props}
                />

                <Button
                    variant="secondary"
                    onClick={props.onClose}
                >Close</Button>
            </ButtonToolbar>

            {showDialog && (
                <dialog open style={{ border: 'none', padding: 0 }}>
                    <Card className="shadow-sm">
                        <Card.Body>
                            <p>
                                Enquiry number must not include {invalidChars}
                            </p>
                            <Button variant="warning" onClick={() => setShowDialog(false)}>Close</Button>
                        </Card.Body>
                    </Card>
                </dialog>
            )}

        </Navbar>
    );
}

export default connect(
    state => state.screening,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Toolbar)