import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../store/Screen/actions';
import { Row, Col, Form, Table } from 'react-bootstrap';
import OptionalExtrasShoppingList from './OptionalExtrasShoppingList';
import QuoteScreenSelector from '../ScreenSelector';

export class QuoteCostsStage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            weight: 0,
            costPerKilo: 3,
            fabricationMargin: 55,
            defaultMargin: 55,
            optionalExtras: []
        };
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps) {

        if (this.props.selectedScreen !== prevProps.selectedScreen) {

            //the screen has changed, we need to update the costs form

            this.setState({ optionalExtras: null });

            let data = this.props.costs[this.props.selectedScreen];

            if (data) {
                this.setState({
                    weight: data.weight,
                    costPerKilo: data.costPerKilo,
                    optionalExtras: data.optionalExtras
                });
            } else this.init();

        }
    }

    init() {

        this.populateForm().then(async () => {

            if (!this.props.previousQuoteConfig) {
                if (this.props.screens[this.props.selectedScreen].calculatedScreen) {

                    let screen = this.props.screens[this.props.selectedScreen].calculatedScreen;

                    var vibroQty = screen.bearingLife.vibratorQuantity > 1 ? screen.bearingLife.vibratorQuantity : "";
                    this.trySelect("VIBRO", [vibroQty + screen.bearingLife.vibratorUnit.toString()]);
                    this.trySelect("MOTOR", [screen.bearingLife.recommendedPower.toString()]);
                    this.trySelect("SHAFT", [screen.widthMM.toString(), screen.angle > 0 ? "vibrex" : "eliptex"]);
                    this.trySelect("FEEDL", [screen.bearingLife.feedboxMaterial]);
                    this.trySelect("LIPL", [screen.bearingLife.lipMaterial]);
                    this.trySelect("DRIVE", ["V Belt Drive"]);
                    this.trySelect("SHOCK", ["Rubber Mounts"]);
                    this.trySelect("PAINT", ["Standard Paint Specification"]);
                }
            }
            this.updateCostsModel();
        });
    }

    trySelect = async (category, values) => {

        var selectOpts = this.state.optionalExtras.filter(x => x.category === category);

        var found = selectOpts.find((e) => {

            var cleanName = e.name.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '')
                .replace(/\s/g, '').toUpperCase();

            if (values && values.length > 1) {

                let isMatch = true;
                for (var i = 0; i < values.length; i++) {

                    if (!cleanName.includes(values[i].toUpperCase()))
                        isMatch = false;
                }

                if (isMatch) return e;

            } else {
                //exact match only
                if (cleanName === values[0].toUpperCase()) {
                    return e;
                }
            }
        });

        var index = found ? selectOpts.indexOf(found) : 0;

        this.handleChangeSelection(category, { target: { value: selectOpts[index].name } });
    }

    populateForm = async () => {
        await this.props.getQuoteItems().then(() => {


            if (this.props.previousQuoteConfig && this.props.previousQuoteConfig.costs) {


                //then we can take the optionalExtras from there for the current screen...
                var thisCost = this.props.previousQuoteConfig.costs[this.props.selectedScreen];

                if (thisCost !== undefined) {
                    this.setState({ ...thisCost });
                    return;
                }
            }


            var optionalExtras = [];

            for (var i in this.props.quoteItems) {
                var extra = this.props.quoteItems[i];

                optionalExtras.push({
                    id: '_' + Math.random().toString(36).substr(2, 9),
                    quoteItemId: extra.id,
                    name: extra.name,
                    qty: extra.qty,
                    price: extra.value,
                    margin: this.state.defaultMargin,
                    sellPrice: extra.value / ((100 - this.state.defaultMargin) / 100),
                    included: extra.included,
                    category: extra.category,
                    order: extra.order
                });

            }
            this.setState({
                weight: this.props.fabricatedWeight ? this.props.fabricatedWeight : 0,
                optionalExtras
            }, () => this.updateCostsModel());

        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => this.updateCostsModel());
    }

    handleChangeExtra = (id, e) => {

        var optionalExtras = Object.assign([], this.state.optionalExtras);
        var pos = optionalExtras.map(function (x) { return x.id; }).indexOf(id);

        if (e.target.name === "included_" + id) {
            optionalExtras[pos]["included"] = e.target.value;
        } else {
            optionalExtras[pos][e.target.name] = parseFloat(e.target.value);
            optionalExtras[pos]["sellPrice"] = (optionalExtras[pos]["price"] * optionalExtras[pos]["qty"]) / ((100 - optionalExtras[pos]["margin"]) / 100);
        }

        this.setState({ optionalExtras }, () => this.updateCostsModel());
    }

    handleChangeSelection = (category, e) => {

        var optionalExtras = Object.assign([], this.state.optionalExtras);

        let current = optionalExtras.filter(extra => extra.category === category)
            .filter(extra => extra.name === e.target.name || extra.name === e.target.value)[0];


        //Ensure that vibro is always included
        if (category === "VIBRO")
            current.included = "included";

        let includedValue = current ? current.included : null;

        optionalExtras.forEach((extra) => {
            if (extra.category === category) {
                if (includedValue !== null) {
                    if (extra.name.toLowerCase() === e.target.value.toLowerCase()) {
                        extra.included = includedValue;
                        extra.selected = true;
                    } else {
                        extra.included = "excluded"; extra.selected = false;
                    }
                }
            }
        });


        this.setState({ optionalExtras }, () => this.updateCostsModel());
    }

    updateCostsModel = () => {
        var model = {
            weight: this.state.weight,
            costPerKilo: this.state.costPerKilo,
            fabricationMargin: this.state.fabricationMargin,
            optionalExtras: this.state.optionalExtras
        };

        this.props.onUpdate(model);
    }

    render() {

        const fabricatedTotal = (this.state.weight * this.state.costPerKilo) / ((100 - this.state.fabricationMargin) / 100);

        const includedExtrasTotal = this.state.optionalExtras && this.state.optionalExtras.some(x => x.included === "included") ?
            this.state.optionalExtras.filter(x => x.included === "included").reduce((prev, cur) => prev + cur.sellPrice, 0)
            : 0;

        return (
            <React.Fragment>

                <QuoteScreenSelector screens={this.props.screens} />

                <Row className="quote-dialog-row" >
                    <Col xs={12}>
                        <Table borderless size="sm">
                            <thead>
                                <tr className="quote-costs-table-heading">
                                    <th className="wider-th">Fabricated Weight (Kg)</th>
                                    <th className="default-th">£/Kg</th>
                                    <th className="default-th">Margin(%)</th>
                                    <th />
                                    <th className="wider-th">Fabricated</th>
                                    <th className="wider-th">Included</th>
                                    <th className="wider-th">Total Price</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Form.Control
                                            name="weight"
                                            type="number"
                                            step={1}
                                            value={this.state.weight}
                                            onChange={(e) => this.handleChange(e)}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            name="costPerKilo"
                                            type="number"
                                            step={1}
                                            value={this.state.costPerKilo}
                                            onChange={(e) => this.handleChange(e)}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            name="fabricationMargin"
                                            type="number"
                                            value={this.state.fabricationMargin}
                                            onChange={(e) => this.handleChange(e)}
                                        />
                                    </td>
                                    <td style={{ width: '5em' }} />
                                    <td>
                                        <Form.Control
                                            type="text"
                                            value={"£" + fabricatedTotal.toFixed(2)}
                                            readOnly
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="text"
                                            value={"£" + includedExtrasTotal.toFixed(2)}
                                            readOnly
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="text"
                                            value={"£" + (fabricatedTotal + includedExtrasTotal).toFixed(2)}
                                            readOnly
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <hr />
                {
                    this.state.optionalExtras &&

                    <OptionalExtrasShoppingList
                        optionalExtras={this.state.optionalExtras}
                        onUpdate={(id, e) => this.handleChangeExtra(id, e)}
                        onUpdateSelection={(category, e) => this.handleChangeSelection(category, e)}
                    />

                }
            </React.Fragment >
        );
    }
}

export default connect(
    state => state.screening,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(QuoteCostsStage);
