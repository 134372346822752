import React from 'react';
import { Form, Row, Col, Card, Button, Accordion } from 'react-bootstrap';

function IsCutInvalid(deck, cutIndex) {

    return deck.cutInvalid[cutIndex] ||
        deck.cuts[cutIndex].Size === "" ||
        isNaN(deck.cuts[cutIndex].Size);
        
}

function DeckDetail(props) {

    return (
        <Card>
            <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
                Deck Details
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
                <>
                    <Card.Body>
                        <Row className="deck-header">
                            <Col>Deck</Col>
                            <Col >MM</Col>
                            <Col >MM</Col>
                            <Col >MM</Col>
                            <Col md="1" />
                        </Row>
                        {props.decks.map(function (deck, index) {
                            return (
                                <React.Fragment key={index}>
                                    <Row className="deck-item" key={index}>
                                        <Col >{index + 1}</Col>
                                        <Col >
                                            <Form.Control
                                                autoComplete="off"
                                                isInvalid={IsCutInvalid(deck, 0)}
                                                type="number"
                                                name="cuts[]"
                                                value={deck.cuts[0].Size}
                                                onChange={props.onChange.bind(null, index, 0)}
                                                required
                                            /> 
                                        </Col>
                                        <Col >
                                            <Form.Control
                                                autoComplete="off"
                                                isInvalid={IsCutInvalid(deck, 1)}
                                                type="number"
                                                name="cuts[]"
                                                value={deck.cuts[1].Size}
                                                onChange={props.onChange.bind(null, index, 1)}
                                                required
                                            />
                                        </Col>
                                        <Col >
                                            <Form.Control
                                                autoComplete="off"
                                                isInvalid={IsCutInvalid(deck, 2)}
                                                type="number" name="cuts[]"
                                                value={deck.cuts[2].Size}
                                                onChange={props.onChange.bind(null, index, 2)}
                                                required
                                            />
                                        </Col>
                                        <Col >
                                            <Button onClick={props.onDeleteItem.bind(null, index)}>
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="deck-item">
                                        <Col md="12">
                                            <select
                                                className="form-control"
                                                value={deck.material}
                                                onChange={props.onSelectMaterial.bind(this, index)}
                                            >
                                                {props.materials && props.materials.map(function (item) {
                                                    return <option key={item.id} value={item.id}>{item.material}</option>;
                                                })}

                                            </select>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            );
                        })}

                    </Card.Body>
                    <Card.Footer>
                        <Button
                            disabled={props.decks.length >= 4}
                            onClick={props.onNewItem}>Add Deck</Button>
                    </Card.Footer>
                </>
            </Accordion.Collapse>
        </Card>
    );
}

export default DeckDetail;