import React from 'react';
import { Alert, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import logo from './assets/img/hri-logo-23.jpg';
import Access from './lib/Access';
import Api from './Api';

export default class NavMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: null,
            isAdmin: null,
            sandbox: false
        };
    }

    componentDidMount() {

        Api.isUserAuthenticated().then(async (response) => {
            var result = await response.json();
            this.setState({ isAuthenticated: result.isAuthenticated, sandbox: result.sandbox });
        }).catch(errors => console.log(errors));

        Api.isUserInRole("Super User").then(async (response) => {

            var result = await response.json();
            this.setState({ isAdmin: result.isInRole });
        }).catch(errors => console.log(errors));
    }

    render() {
        let displayName = Access.getDisplayName();
        const signOut = () => {
            Api.signOut().then(() => {
                window.location = "/";
            });
        };

        return (
            <Navbar className="primary-navbar">
                <Navbar.Brand href="#home">
                    <img
                        alt=""
                        src={logo}
                        height="60"
                        className="d-inline-block align-top navlogo"
                    />
                </Navbar.Brand>
                {this.state.sandbox &&
                    <Navbar.Text>
                        <small>
                            <Alert style={{ marginBottom: 0 }} variant="warning"><i className="fas fa-alert" /> Sandbox Environment</Alert>
                        </small>
                    </Navbar.Text>
                }
                {this.state.isAuthenticated &&
                    <React.Fragment>

                        <Nav className="mr-auto">
                            <Nav.Link href="/designer">Screen Designer</Nav.Link>
                            <Nav.Link href="/enquiries">Enquiries</Nav.Link>
                            {this.state.isAdmin &&
                                <Nav.Link href="/admin">Administration</Nav.Link>
                            }

                        </Nav>
                        <Nav>
                            {displayName !== null &&
                                <NavDropdown title={displayName} id="collasible-nav-dropdown">
                                    <NavDropdown.Item onClick={() => { signOut(); }}>Sign Out</NavDropdown.Item>
                                </NavDropdown>
                            }
                        </Nav>
                    </React.Fragment>
                }

            </Navbar>
        );
    }
}
