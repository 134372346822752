import React from 'react';
import { cloneDeep } from 'lodash';
import { Col, Form, Row, ButtonGroup, Button, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loading from '../../../Common/Loading';
import { actionCreators } from '../../../store/Quotes/actions';
export class CommercialTermsStage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            quoteTerms: null,
            selectedTerm: null,
            newTerm: null,
        };
    }

    componentDidMount() {

        if (this.props.previousQuoteConfig && this.props.previousQuoteConfig.terms)
            this.setState({ quoteTerms: this.props.previousQuoteConfig.terms }, () => {
                this.updateTerms();
            });
        else
            this.props.getQuoteTerms().then(() => {
                this.setState({ quoteTerms: this.props.quoteTerms }, () => {
                    this.updateTerms();
                });
            });
    }

    componentDidUpdate() {
        //terms are for all screens on the quote so nothing
        //needs to update if the selected screen changes.
    }

    handleChange = (e) => {

        let updated = Object.assign([], this.state.quoteTerms);

        updated.forEach(x => {
            if (x.id === e.target.name)
                x.details = e.target.value;
        });

        this.setState({ quoteTerms: updated, selectedTerm: null, newTerm: null },
            this.updateTerms()
        );
    }

    handleDelete = () => {
        this.props.removeQuoteTerm(this.state.selectedTerm.id).then(() => {
            let updatedTerms = this.state.quoteTerms.filter(x => x.id !== this.state.selectedTerm.id);
            this.setState({
                quoteTerms: Object.assign([], updatedTerms),
                selectedTerm: null
            }, () => this.updateTerms());
        });
    }

    handleAdd = () => {

        this.props.addQuoteTerm(this.state.newTerm).then(() => {
            let added = this.props.quoteTerms.filter(x => x.heading === this.state.newTerm)[0];
            var updated = cloneDeep(this.state.quoteTerms);

            if (added) {
                updated.push(added);
            }

            this.setState({ quoteTerms: updated, newTerm: null });

        });
    }

    handleSaveTerm = (term) => {

        let terms = cloneDeep(this.state.quoteTerms);
        terms.forEach(x => { x.saved = undefined });

        this.setState({ quoteTerms: terms }, () => {
            this.props.updateQuoteTerm(term.id, term.details).then(() => {
                terms.forEach(x => {
                    if (x.id === term.id)
                        x.saved = true;
                });
                this.setState({ quoteTerms: terms });
            });
        });

    }



    updateTerms = () => {
        this.props.onUpdate(this.state.quoteTerms);
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.state.quoteTerms ?
                        this.state.quoteTerms.map((term, i) => {
                            return <Row className="quote-dialog-row" key={i + term.heading}>
                                <Col>
                                    <Form.Label>{term.heading}</Form.Label>
                                    <span style={{ float: 'right', textAlign: 'right' }}>
                                        {term.saved && <small style={{ marginRight: '.5em' }}>updated default.</small>}
                                        <ButtonGroup size="sm">
                                            <OverlayTrigger
                                                placement="left"
                                                overlay={
                                                    <Tooltip >Save as the default details for '{term.heading}'</Tooltip>
                                                }
                                            >
                                                <Button
                                                    onClick={() => this.handleSaveTerm(term)}
                                                >
                                                    <i className="fas fa-save" />
                                                </Button>
                                            </OverlayTrigger>
                                            {term.createdDate ?
                                                <OverlayTrigger
                                                    placement="left"
                                                    overlay={
                                                        <Tooltip >Delete the commercial term heading '{term.heading}'</Tooltip>
                                                    }
                                                >
                                                    <Button
                                                        variant="danger"
                                                        onClick={() => this.setState({ selectedTerm: term })}
                                                    ><i className="fas fa-trash-alt" /></Button>
                                                </OverlayTrigger>
                                                : <Button
                                                    variant="outline-secondary"
                                                    disabled

                                                ><i className="fas fa-trash-alt" /></Button>
                                            }

                                        </ButtonGroup>



                                        {this.state.selectedTerm === term && <p style={{ marginTop: '1em' }}>
                                            <Form.Text variant="danger">This heading and detail will be removed from all future quotes.</Form.Text>
                                            <Button
                                                size="sm"
                                                variant="danger"
                                                onClick={this.handleDelete}

                                            >
                                                Understood. <strong>Delete</strong> {term.heading}
                                            </Button>
                                        </p>}

                                    </span>

                                    <Form.Control
                                        as={"textarea"}
                                        onChange={this.handleChange}
                                        name={term.id}
                                        value={term.details}
                                    />

                                    {i === (this.state.quoteTerms.length - 1) && this.state.newTerm === null &&
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={
                                                <Tooltip >Add a new commercial term heading</Tooltip>
                                            }
                                        >
                                            <Button
                                                variant="primary"
                                                onClick={() => this.setState({ newTerm: "" })}
                                                style={{ marginTop: '1em' }}
                                            >
                                                <i className="fas fa-plus" />
                                            </Button>
                                        </OverlayTrigger>
                                    }

                                </Col>
                            </Row>
                        }) : <Loading loadMessage={"getting commercial terms"} />
                }


                {this.state.newTerm != null &&
                    <Row className="quote-dialog-row">
                        <Col style={{ maxWidth: '600px' }}>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    onChange={(e) => this.setState({ newTerm: e.target.value })}
                                    name="New Term"
                                    placeholder="Enter new commercial term heading"
                                    value={this.state.newTerm}
                                />
                                <InputGroup.Append>
                                    <OverlayTrigger
                                        placement="left"
                                        overlay={
                                            <Tooltip > Cancel commercial term heading </Tooltip>
                                        }
                                    >
                                        <Button
                                            variant="secondary"
                                            onClick={() => { this.setState({ newTerm: null }) }}
                                        >
                                            <i className="fas fa-times" />
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="left"
                                        overlay={
                                            <Tooltip>Save commercial term heading </Tooltip>
                                        }
                                    >
                                        <Button
                                            variant="primary"
                                            onClick={() => this.handleAdd()}
                                        >
                                            <i className="fas fa-save" />
                                        </Button>
                                    </OverlayTrigger>
                                </InputGroup.Append>
                            </InputGroup>
                            <Form.Text muted>This Commercial Terms heading will be available on future quotes.</Form.Text>
                        </Col>
                    </Row>
                }

            </React.Fragment>
        );
    }
}

export default connect(
    state => state.quotes,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(CommercialTermsStage);
