import {
    RECEIVED_ENQUIRY_NUMBER,
    RECEIVED_ENQUIRY,
    RECEIVED_ENQUIRIES,
    SAVING_ENQUIRY,
    UPDATE_NUMBER_OF_SCREENS_FOR_ENQUIRY
} from '../action-types';

const initialState = {
    currentEnquiry: null,
    enquiries: null,
    saveInProgress: false,
    saveComplete: false,
    numberOfScreens: 1
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case RECEIVED_ENQUIRY_NUMBER:
            return {
                ...state,
                nextEnquiryNumber: action.nextEnquiryNumber
            };
        case RECEIVED_ENQUIRY:
            return {
                ...state,
                currentEnquiry: action.enquiry
            };
        case RECEIVED_ENQUIRIES:
            return {
                ...state,
                enquiries: action.enquiries
            };
        case SAVING_ENQUIRY:
            return {
                ...state,
                saveInProgress: action.inProgress,
                saveComplete: action.complete
            };        
        default:
            return state;
    }
};