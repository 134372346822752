import React from 'react';
import CutSummary from "./CutSummary";
import CutFactor from "./CutFactors";
import CutProperties from "./CutProps";
import CutBreakdown from "./CutBreakdown";
import { Row, Col, Tab, Nav } from 'react-bootstrap';

function SelectedCut(props) {

    const { factors, analysisSummary, onChange } = props;
    
    return (
        props ?
            <Tab.Container id="selected-cuts-tabs" defaultActiveKey="first">
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Summary</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Factors</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third">Properties</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="forth">Breakdown</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <CutSummary {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <CutFactor factors={factors} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <CutProperties
                                    properties={props}
                                    {...props}
                                    onChange={onChange}
                                    adjustCutProperties={props.adjustCutProperties}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="forth">
                                <CutBreakdown summary={analysisSummary} overs={factors.percentageOvers} fines={factors.percentageFines} cut={props.cutSize} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
            : <div>Select Cut To View Details</div>
    );
}

export default SelectedCut;