import React from 'react';
import { Modal } from 'react-bootstrap';
import CutBreakdownGraph from './CutBreakdownGraph';

const CutBreakdownDialog = ({ open, breakdown, onClose, overs, fines, cut}) => (

    <Modal show={open} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Material Breakdown</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <CutBreakdownGraph breakdown={breakdown} overs={overs} fines={fines} cut={cut} />
        </Modal.Body>
    </Modal>
);

export default CutBreakdownDialog;