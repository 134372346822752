import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Quotes/actions';
import { Row, Col, Tab, Nav } from 'react-bootstrap';

export class QuoteScreenSelector extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        return (
            this.props.screens.length === 1 ? null :
                <Tab.Container defaultActiveKey={this.props.selectedScreen} activeKey={this.props.selectedScreen}>
                    <Row className="quote-dialog-row">
                        <Col>
                            <Nav variant="pills">

                                {this.props.screens.map((s, i) => {
                                    return (
                                        <Nav.Item key={i}>
                                            <Nav.Link
                                                eventKey={i}
                                                onClick={() => this.props.updateSelectedScreen(i)}
                                            >
                                                {s.itemName}
                                            </Nav.Link>
                                        </Nav.Item>
                                    );
                                })}
                            </Nav>
                        </Col>
                    </Row>
                </Tab.Container>
        );
    }
}

export default connect(
    state => state.quotes,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(QuoteScreenSelector);
