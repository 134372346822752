import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import CutBreakdownDialog from './CutBreakdownDialog';

function CutBreakdown({ summary, overs, fines, cut }) {
   
    const [open, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleOpen = () => setShow(true);
   
    const graphData = [
        {
            "data": summary.map(function (item, i) {
                return { id: i, x: 100 - (10 * i), y: item };
            })
        }
    ];

    return (
        <>
            <div className="property-table">
                <Row>
                    <Col className="property-title">Breakdown</Col>

                </Row>
                <Row>
                    <Col>%</Col>
                    <Col>MM</Col>
                </Row>

                {summary.map(function (item, i) {
                    return (
                        <Row key={i}>
                            <Col>{100 - (10 * i)}</Col>
                            <Col>{Number(item).toFixed(1)}</Col>
                        </Row>);
                }
                )}
            </div>
            <Button onClick={handleOpen} style={{ width: "100%" }}>View Graph</Button>
            <CutBreakdownDialog open={open} onClose={handleClose} breakdown={graphData} overs={overs} fines={fines} cut={cut} />
        </>);
}

export default CutBreakdown;