import Access from '../lib/Access';
import Screen from "./Screen";
import Enquiry from './enquiry';
import Quote from './quote';

export default {
    Screen,
    Enquiry,
    Quote,

    signIn: (options) => fetch(`api/Account/SignIn`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(options)
    }),
    signOut: () => fetch(`api/Account/SignOut`, {
        method: "GET",
        headers: {
            "Authorization": 'Bearer ' + Access.getCachedToken()
        }
    }),
    isUserAuthenticated: () => fetch(`api/Account/IsUserAuthenticated`, {
        method: "GET",
        headers: {
            "Authorization": 'Bearer ' + Access.getCachedToken()
        }
    }),
    isUserInRole: (role) => fetch(`api/Account/IsUserInRole?role=` + role, {
        method: "GET",
        headers: {
            "Authorization": 'Bearer ' + Access.getCachedToken()
        }
    }),
    getUsers: () => fetch(`api/Account/GetUsers`, {
        method: "GET",
        headers: {
            "Authorization": 'Bearer ' + Access.getCachedToken()
        }
    }),
    getRoles: () => fetch(`api/Account/GetRoles`, {
        method: "GET",
        headers: {
            "Authorization": 'Bearer ' + Access.getCachedToken()
        }
    }),
    addUser: (options) => fetch(`api/Account/AddUser`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": 'Bearer ' + Access.getCachedToken()
        },
        body: JSON.stringify(options)
    }),

    resetUserPassword: (options) => fetch(`api/Account/ResetUserPassword`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": 'Bearer ' + Access.getCachedToken()
        },
        body: JSON.stringify(options)
    }),
};