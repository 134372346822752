/* eslint react/no-multi-comp: 0 */
import React from 'react';
import { ResponsiveLine } from '@nivo/line';

function CutBreakdownGraph(props) {
   
    const cut = props.cut;
    const overs = props.overs;
    const fines = props.fines;
    
    const refsLayer = (props) => {
        return (
            <g>
                <rect
                    y={props.yScale(cut / 2)}
                    x={props.xScale(fines)}
                    width={props.xScale(100) - props.xScale(fines)}
                    height={props.yScale(0) - props.yScale(cut / 2)}
                    stroke="blue"
                    fill="none"
                />
                <rect
                    y={props.yScale(cut)}
                    x={props.xScale(100 - (Number(overs) === 0 ? 10 : overs))}

                    width={props.xScale(100) - props.xScale(100 - overs)}
                    height={props.yScale(0) - props.yScale(cut)}
                    stroke="green"                   
                    fill="none"
                />
            </g>);
    };

    return (
        <div style={{ height: "500px", width: "500px" }}>
            <ResponsiveLine
                data={props.breakdown}
                layers={[
                    "grid",
                    refsLayer,
                    "markers",
                    "axes",
                    "areas",
                    "lines",
                    "slices",
                    "dots"
                ]}
                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                xScale={{ type: 'linear', min: 0 }}
                yScale={{ type: 'linear', stacked: true, min: 0, max: 'auto' }}
                axisTop={null}
                axisLeft={null}
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0

                }}
                axisRight={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    format: value => `${Number(value)} mm`
                }}
                curve="monotoneX"
                colors={ "green" }
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabel="y"
                pointLabelYOffset={-12}
                useMesh
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
            />
        </div>
    );
}

export default CutBreakdownGraph;