import React, { useEffect, useState } from 'react';
import { Button, OverlayTrigger, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PricingAdmin from '../Pricing';
import { actionCreators } from '../store/Quotes/actions';
import AddPriceGroupDialog from './Dialog/AddPriceGroupDialog';
import RemovePriceGroupDialog from './Dialog/RemovePriceGroupDialog';

export const PricingGroups = (props) => {


    const [selected, setSelected] = useState("EXTRA")
    const [pricingItems, setPricingItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [addingPriceGroup, setAddingPriceGroup] = useState(false);
    const [addingPrice, setAddingPrice] = useState(false);
    const [removingPrice, setRemovingPrice] = useState(null);
    const [removingPriceGroup, setRemovingPriceGroup] = useState(false);


    const [loading, setLoading] = useState(true);

    const defaultCategories = [
        ["Optional Extras", "Item Name", "EXTRA"],
        ["Installation", "Work Required", "INSTALLATION"],
        ["Vibrator Units", "Model Name", "VIBRO"],
        ["Motors", "KW", "MOTOR"],
        ["Shafts", "Type", "SHAFT"],
        ["Suspension", "Type", "SHOCK"],
        ["Drive", "Type", "DRIVE"],
        ["Feedbox", "Liner Material", "FEEDL"],
        ["Discharge Lip", "Liner Material", "LIPL"],
        ["Paint", "Type", "PAINT"]
    ];

    const customCategories = () => {
        let custom = [];
        let def = defaultCategories.map(x => x[2]);

        categories.forEach(cat => {
            if (!def.includes(cat))
                custom.push(cat);
        });

        return custom;
    };


    const getCategory = () => {
        return customCategories().includes(selected) ?
            [selected, "Option", selected] :
            defaultCategories.filter(x => x[2] === selected)[0];
    }

    const handleSelectTab = (evtKey) => {
        if (evtKey === "ADD_PRICE_GROUP") {


            //do something here...
            console.log("clicked tab");


        } else {
            setSelected(evtKey);
        }
    }

    const getItems = async () => {
        await props.getQuoteItems();
    }

    const setItems = () => {

        //if nothing has changed theres no need to update state
        if (JSON.stringify(pricingItems) === JSON.stringify(props.quoteItems))
            return;


        let categories = [];
        if (props.quoteItems)
            props.quoteItems.forEach(x => {
                if (!categories.includes(x.category))
                    categories.push(x.category);
            });

        console.log("setting items", props.quoteItems);
        console.log("setting categories", categories);
        setPricingItems(props.quoteItems);
        setCategories(categories);

    }


    const handleComplete = () => {
        setLoading(true);
        props.getQuoteItems();
        setAddingPriceGroup(false);
        setAddingPrice(false);
        setRemovingPrice(null);
        setRemovingPriceGroup(false);
    }

    useEffect(() => {
        setLoading(true);

        if (props.quoteItems)
            setItems();
        else getItems();

        setLoading(false);


    }, [props.quoteItems])


    return (
        <>
            {loading ? <p>please wait..</p> : <>
                <Tabs
                    activeKey={selected}
                    onSelect={(evtKey, e) => handleSelectTab(evtKey)}
                    className="price-group-tabs"
                    variant="pills"
                    style={{
                        margin: '2em 0'
                    }}
                >

                    {defaultCategories.map(cat => {
                        return (
                            <Tab eventKey={cat[2]} title={cat[0]} >
                                <PricingAdmin
                                    title={cat[0]}
                                    columnLabel={cat[1]}
                                    filterCategory={cat[2]}
                                    onAddPrice={() => { setAddingPrice(true) }}
                                    onRemovePrice={(id) => { setRemovingPrice(id) }}
                                />
                            </Tab>
                        );
                    })}

                    {customCategories().map(cat => {
                        return (
                            <Tab eventKey={cat} title={cat} >
                                <PricingAdmin
                                    title={cat}
                                    columnLabel={"Option"}
                                    filterCategory={cat}
                                    onAddPrice={() => { setAddingPrice(true) }}
                                    onRemovePrice={(id) => { setRemovingPrice(id) }}
                                    onRemovePriceGroup={() => { setRemovingPriceGroup(true) }}

                                />
                            </Tab>
                        );
                    })}

                    <Tab eventKey="ADD_PRICE_GROUP" title={
                        <OverlayTrigger
                            placement="left"
                            overlay={
                                <Tooltip > Add a Price Category </Tooltip>
                            }
                        >
                            <span style={{ position: 'absolute' }}>

                                <Button
                                    style={{ margin: '-1em .25em -.8em' }}
                                    variant="primary"
                                    onClick={() => { setAddingPriceGroup(true); }}
                                    title="+"
                                >
                                    <i className="fas fa-plus" />
                                </Button>
                            </span>

                        </OverlayTrigger>

                    } />
                </Tabs>


                {addingPriceGroup &&
                    <AddPriceGroupDialog
                        onComplete={() => { handleComplete(); }}
                    />
                }

                {addingPrice &&
                    <AddPriceGroupDialog
                        onComplete={() => { handleComplete(); }}
                        category={getCategory()}
                    />
                }

                {removingPrice &&
                    <RemovePriceGroupDialog
                        onComplete={() => { handleComplete(); }}
                        category={getCategory()}
                        id={removingPrice}
                    />
                }

                {removingPriceGroup &&
                    <RemovePriceGroupDialog
                        onComplete={() => { handleComplete(); }}
                        category={getCategory()}
                    />
                }

            </>
            }


        </>
    );

}


export default connect(
    state => state.quotes,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(PricingGroups);