import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import ScreenSummary from './ScreenSummary';
import WaterRequirements from './WaterRequirements';
import BearingLife from './BearingLife';

function SummaryTabs(props) {
    return (
        <Tabs defaultActiveKey="screen" className="administration-tabs">
            <Tab
                eventKey="screen"
                title={
                    <span> Screen <i className="fas fa-object-ungroup" style={{ marginLeft: '.5em' }} /> </span>
                }
            >
                <ScreenSummary {...props} />
            </Tab>
            {props.waterRequirements &&
                <Tab
                    eventKey="water"
                    title={
                        <span> Water Requirements <i className="fas fa-tint" style={{ marginLeft: '.5em' }} /> </span>
                    }
                >
                    <WaterRequirements {...props} />
                </Tab>
            }
            <Tab
                eventKey="bearing"
                title={
                    <span> Bearing Life <i className="fas fa-life-ring" style={{ marginLeft: '.5em' }} /> </span>
                }
            >
                <BearingLife {...props} />
            </Tab>
        </Tabs>
    );
}

export default SummaryTabs;