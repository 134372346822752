import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../store/Screen/actions';
import { Row, Col, Table, Form, InputGroup, Button } from 'react-bootstrap';
import FeedboxWeightDialog from './FeedboxWeightDialog';
import Loading from '../../../Common/Loading';
import LipWeightDialog from './LipWeightDialog';

class BearingLife extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            feedboxDialogOpen: false,
            lipDialogOpen: false
        };      
    }

    onUpdate = (e, index) => {
        this.props.updateBearingProperites(this.props.selectedScreenIndex, e.target.name, e.target.value, index);
    }

    onUpdated = (e) => {        
        if (this.props.rebuild)
            this.props.rebuildScreen(this.props.selectedScreenIndex).then(() => {
                this.setState({ eliptexOptions: this.props.angle > 0 ? false : true });
                this.props.onUpdate();
            });
    }

    handleOpenFeedboxDialog() {
        this.setState({ feedboxDialogOpen: true });
    }

    handleOpenLipDialog() {
        this.setState({ lipDialogOpen: true });
    }

    handleCloseDialog() {
        this.setState({
            feedboxDialogOpen: false,
            lipDialogOpen: false,
        }, () => { this.onUpdated(); });
    }

    render() {
        const { bearingLife, decks, properties } = this.props;
        const deckCount = decks ? decks.length : 0;
        const eliptex = properties.angleType > 0 ? true : false;

        return (
            <>
                {bearingLife ?
                    <Row>
                        <Col xs={4}>
                            <Form.Group as={Row}>
                                <Form.Label column>Vibrated Weight</Form.Label>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>Kg</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            type="number"
                                            name="vibratedWeight"
                                            value={bearingLife.vibratedWeight}
                                            readOnly
                                            disabled
                                        />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column>Additional Weight</Form.Label>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>Kg</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            type="number"
                                            name="otherWeight"
                                            onChange={(e) => { this.onUpdate(e); }}
                                            onBlur={(e) => { this.onUpdated(e); }}
                                            value={bearingLife.otherWeight}
                                        />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column>Feedbox</Form.Label>
                                <Col>
                                    <InputGroup>
                                        <Form.Control as="select"
                                            className={"readonly-select"}
                                            name="feedboxMaterial"
                                            value={bearingLife.feedboxMaterial}
                                            readOnly
                                            disabled
                                        >
                                            <option value="MildSteel">Mild Steel</option>
                                            <option value="ARSteel">A. R. Steel</option>
                                            <option value="Hardox400">Hardox 400</option>
                                            <option value="Rubber">Rubber</option>
                                            <option value="Polyurethane">Polyurethane</option>
                                        </Form.Control>
                                        <InputGroup.Append>
                                            <Button
                                                variant="outline-secondary"
                                                onClick={() => { this.handleOpenFeedboxDialog(); }}
                                            >
                                                Edit
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column>Lip</Form.Label>
                                <Col>
                                    <InputGroup>
                                        <Form.Control as="select"
                                            className={"readonly-select"}
                                            name="lipMaterial"
                                            value={bearingLife.lipMaterial}
                                            readOnly
                                            disabled
                                        >
                                            <option value="MildSteel">Mild Steel</option>
                                            <option value="ARSteel">A. R. Steel</option>
                                            <option value="Hardox400">Hardox 400</option>
                                            <option value="Rubber">Rubber</option>
                                            <option value="Polyurethane">Polyurethane</option>
                                        </Form.Control>
                                        <InputGroup.Append>
                                            <Button
                                                variant="outline-secondary"
                                                onClick={() => { this.handleOpenLipDialog(); }}
                                            >
                                                Edit
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group as={Row}>
                                <Form.Label column>G Rating</Form.Label>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>G</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            type="number"
                                            name="g"
                                            step={0.01}
                                            onChange={(e) => { this.onUpdate(e); }}
                                            onBlur={(e) => { this.onUpdated(e); }}
                                            value={bearingLife.g}
                                        />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column >Speed Selection</Form.Label>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>RPM</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control as="select"
                                            name="selectedSpeed"
                                            onChange={(e) => { this.onUpdate(e); }}
                                            onBlur={(e) => { this.onUpdated(e); }}
                                            value={bearingLife.selectedSpeed}
                                        >
                                            {bearingLife && bearingLife.strokeModels.strokes.map(s => {
                                                return (
                                                    <option key={s.speed} value={s.speed}>{s.speed}</option>
                                                );
                                            })}
                                        </Form.Control>
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column >Stroke Length</Form.Label>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>mm</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control as="select"
                                            name="selectedSpeed"
                                            onChange={(e) => { this.onUpdate(e); }}
                                            onBlur={(e) => { this.onUpdated(e); }}
                                            value={bearingLife.selectedSpeed}
                                        >
                                            {bearingLife && bearingLife.strokeModels.strokes.map(s => {
                                                return (
                                                    <option key={s.speed} value={s.speed}>{(Math.round(s.stroke * 100) / 100).toFixed(2)}</option>
                                                );
                                            })}
                                        </Form.Control>
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column >Vibrator Unit</Form.Label>
                                <Col>
                                    <InputGroup>
                                        <Form.Control as="select"
                                            style={{ maxWidth: '60px' }}
                                            name="vibratorQuantity"
                                            onChange={(e) => { this.onUpdate(e); }}
                                            onBlur={(e) => { this.onUpdated(e); }}
                                            value={bearingLife.vibratorQuantity}
                                        >

                                            <option value="1">1 x </option>
                                            <option value="2">2 x </option>

                                            {!eliptex &&
                                                <option value="3">3 x </option>
                                            }
                                        </Form.Control>

                                        <Form.Control as="select"
                                            name="vibratorUnit"
                                            onChange={(e) => { this.onUpdate(e); }}
                                            onBlur={(e) => { this.onUpdated(e); }}
                                            value={bearingLife.vibratorUnit}
                                        >
                                            {eliptex ?
                                                <>
                                                    <option value="LPE6">LPE6</option>
                                                    <option value="LPE9">LPE9</option>
                                                    <option value="LPE11">LPE11</option>
                                                    <option value="E13">E13</option>
                                                </>
                                                :
                                                <>
                                                    <option value="V7">V7</option>
                                                    <option value="V9">V9</option>
                                                    <option value="V11">V11</option>
                                                    <option value="V13">V13</option>
                                                    <option value="V14">V14</option>
                                                    <option value="V16">V16</option>
                                                    <option value="QV14">QV14</option>
                                                </>
                                            }

                                        </Form.Control>
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col xs={4}>
                            <h5 style={{ marginBottom: '1em' }}>
                                <i className="fas fa-life-ring fa-spin" style={{ marginRight: '.5em' }} />
                                Bearing Life
                            </h5>
                            <Table striped size="sm">
                                <tbody>
                                    <tr>
                                        <td className="bg-primary text-light" style={{ width: '5em' }}>
                                            <i className="fas fa-clock" style={{ marginRight: '.5em' }} />Hrs
                                        </td>
                                        <td className="text-center font-weight-bold">{bearingLife.bearingHours}</td>
                                    </tr>
                                </tbody>
                            </Table>

                            <h5 style={{ marginBottom: '1em' }}>
                                <i className="fas fa-charging-station" style={{ marginRight: '.5em' }} />
                                Power Requirements
                            </h5>
                            <Table striped size="sm">
                                <tbody>
                                    <tr>
                                        <td className="bg-primary text-light" style={{ width: '5em' }}>
                                            <i className="fas fa-tachometer-alt" style={{ marginRight: '.5em' }} />RPM
                                        </td>
                                        <td>{bearingLife.strokeModels.selectedSpeed}</td>
                                        <td>{bearingLife.strokeModels.selectedSpeed + 100}</td>
                                        <td className="text-center font-weight-bold">Recommended</td>
                                    </tr>
                                    <tr>
                                        <td className="bg-primary text-light" style={{ width: '5em' }}>
                                            <i className="fas fa-bolt" style={{ marginRight: '.5em' }} /> kW
                                        </td>
                                        <td>{bearingLife.lowSpeedPower}</td>
                                        <td>{bearingLife.highSpeedPower}</td>
                                        <td className="text-center font-weight-bold">{bearingLife.recommendedPower}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>

                        {this.state.feedboxDialogOpen &&
                            <FeedboxWeightDialog
                                bearingLife={bearingLife}
                                onUpdate={(e) => { this.onUpdate(e); }}
                                onClose={() => { this.handleCloseDialog(); }}
                            />
                        }

                        {this.state.lipDialogOpen &&
                            <LipWeightDialog
                                decks={deckCount}
                                bearingLife={bearingLife}
                                onUpdate={(e, index) => { this.onUpdate(e, index); }}
                                onClose={() => { this.handleCloseDialog(); }}
                            />
                        }

                    </Row>

                    :
                    <Loading />
                }
            </>
        );
    }
}

export default connect(
    state => state.screening,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(BearingLife);