import React from 'react';
import { Row, Col } from 'react-bootstrap';

const CutFactors = ({ factors, ...props }) => (

    <div className="property-table">
        <Row>
            <Col className="property-title">Cut Factors</Col>
        </Row>
        <Row>
            <Col>Capacity</Col>
            <Col>{Number(factors.capacityF).toFixed(3)}</Col>
        </Row>
        <Row>
            <Col>Fines</Col>
            <Col>({factors.percentageFines}%) {Number(factors.finesFactor).toFixed(3)}</Col>
        </Row>
        <Row>
            <Col>Overs</Col>
            <Col>({factors.percentageOvers}%)&nbsp;
                <span style={{ backgroundColor: (Number(factors.percentageOvers) >= 74 && Number(factors.oversFactor).toFixed(3) === "0.800") ? "green" : ""}}>
                {Number(factors.oversFactor).toFixed(3)}
                </span>
            </Col>
        </Row>
    <Row>
            <Col>Efficiency</Col>
            <Col className={props.screen.calculateEfficiency ? "computed" : ""} >({Number(props.efficiency).toFixed(3)}%) {Number(factors.efficiencyFactor).toFixed(3)}</Col>
    </Row>
    <Row>
        <Col>Deck</Col>
        <Col>{Number(factors.deckFactor).toFixed(3)}</Col>
    </Row>
    <Row>
        <Col>Wet/Mosture</Col>
        <Col>{Number(factors.wetFactor).toFixed(3)}</Col>
    </Row>
    <Row>
        <Col>Shape</Col>
        <Col>{Number(factors.shapeFactor).toFixed(3)}</Col>
    </Row>
    <Row>
        <Col>Slot</Col>
        <Col>{Number(factors.slotFactor).toFixed(3)}</Col>
    </Row>
    <Row>
            <Col>Open Area</Col>
            <Col>{Number(factors.openAreaFactor).toFixed(3)} ({Number(props.openAreaActual).toFixed(0)} / {props.openAreaWire})</Col>
    </Row>
    <Row>
        <Col>Cap Through</Col>
        <Col>{Number(factors.capThrough).toFixed(3)}</Col>
    </Row>
    <Row>
        <Col>Factor Produced</Col>
        <Col>{Number(factors.factorProd).toFixed(3)}</Col>
    </Row>
    </div >
);

export default CutFactors;