import React, { useState } from 'react';
import { Form, InputGroup, Row, Col, Dropdown, SplitButton } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../store/Screen/actions';

function ScreenSummary(props) {

    const [metic, setMetic] = useState(true);
    
    const length = props.properties.UserDefinedLength !== null && props.screenInvalidated ? props.properties.UserDefinedLength : metic ? props.lengthMM : props.lengthFT;
    const width = props.properties.UserDefinedWidth !== null && props.screenInvalidated ? props.properties.UserDefinedWidth : metic ? props.widthMM : props.widthFT;
    const angle = props.properties.UserDefinedAngle !== null && props.screenInvalidated ? props.properties.UserDefinedAngle : Number(props.angle).toFixed(3);

    const onUpdate = (e) => {
        props.updateScreenProperites(props.selectedScreenIndex, e.target.name, e.target.value);
    };
    const handleUpdateUserProps = (e) => {
        if (props.screenInvalidated) {
            props.rebuildScreen(props.selectedScreenIndex)
                .then(() => props.onUpdate(props.selectedScreenIndex));
        }
    };
    const handleCalculateEfficiency = () => {

        props.calculateEfficiency(props.selectedScreenIndex)
            .then(() => props.onUpdate(props.selectedScreenIndex));
    };
    const handleIncreaseCapacity = () => {

        props.increaseCapacity(props.selectedScreenIndex)
            .then(() => props.onUpdate(props.selectedScreenIndex));
    };
    const sizingMethods = [
        "",
        "WIDTH / LENGTH BASED ON AREA REQUIRED ",
        "WIDTH / LENGTH BASED ON RATIO OF BED DEPTH TO AVERAGE PARTICLE SIZE",
        "LENGTH BASED ON INPUTTED WIDTH ",
        "WIDTH BASED ON INPUTTED LENGTH",
        "INPUTTED WIDTH IS INVALID"];
    
    return (
        <React.Fragment>
            <div className="screen-props">
                <Row>
                    <Col md="2">
                        <SplitButton
                            title="Calculate"
                            variant="primary"
                            onClick={() => handleUpdateUserProps()}                            
                        >
                            <Dropdown.Item onClick={() => handleCalculateEfficiency()}>Calculate Efficiency</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleIncreaseCapacity()}>Increase Capacity</Dropdown.Item>
                            <Dropdown.Item onClick={() => props.resetScreen(props.selectedScreenIndex)}>Reset All</Dropdown.Item>
                        </SplitButton>
                    </Col>
                    <Col className="screen-sizing-method">
                        {sizingMethods[props.sizeBasedOn]}
                    </Col>
                    <Col md="2" />
                </Row>
                <Row>
                    <Col>
                        Width
                    </Col>
                    <Col>
                        <InputGroup>
                            <Form.Control
                                inline="true"
                                name="UserDefinedWidth"
                                onChange={(e) => { onUpdate(e); }}
                                value={width}
                            />
                            <InputGroup.Append className="clickable" onClick={() => setMetic(!metic)}>
                                <InputGroup.Text>{metic ? "mm" : "ft"}</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                    <Col>
                        Length
                    </Col>
                    <Col>
                        <InputGroup>
                            <Form.Control
                                inline="true"
                                name="UserDefinedLength"
                                onChange={(e) => { onUpdate(e); }}
                                value={length}
                            />
                            <InputGroup.Append className="clickable" onClick={() => setMetic(!metic)}>
                                <InputGroup.Text>{metic ? "mm" : "ft"}</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>

                    <Col md="3">
                        Angle
                    </Col>
                    <Col md="3">
                        <Form.Control
                            inline="true"
                            name="UserDefinedAngle"
                            onChange={(e) => { onUpdate(e); }}
                            value={angle}
                        />
                    </Col>
                </Row>
            </div>
            <div className="screen-length">{props.lengthMM}mm</div>
        </React.Fragment>
    );
}

export default connect(
    state => state.screening,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ScreenSummary);
