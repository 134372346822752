import React from 'react';
import { Modal, Button } from 'react-bootstrap';

class DeleteEnquiryDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: true,

        };
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    handleConfirm = () => {
        this.props.onConfirm();
        this.handleClose();
    }

    render() {
        const { onComplete } = this.props;
        const { open } = this.state;
        return (
            <Modal show={open}
                centered
                onExited={() => {
                    if (onComplete) {
                        onComplete();
                    }
                }}
            >
                <Modal.Header>
                    <Modal.Title>Delete Enquiry</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p> Are you sure you want to delete <strong>{this.props.enquiry.name}</strong>?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={this.handleConfirm}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default DeleteEnquiryDialog;