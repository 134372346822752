import React from 'react';
import { Button, Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function PriceInput(props) {
    let { id, name, value, order, onRemove } = props;
    return (
        <tr>
            <td>{name}</td>
            <td className="centered">
                <input
                    id={id}
                    type="radio"
                    value="excluded"
                    name={"included_" + name}
                    checked={props.included === "excluded"}
                    onChange={(e) => props.onChangeIncluded(e)}
                    className="custom-radio"
                />
            </td>
            <td className="centered">
                <input
                    id={id}
                    type="radio"
                    value="included"
                    onChange={(e) => props.onChangeIncluded(e)}
                    name={"included_" + id}
                    checked={props.included === "included"}
                    className="custom-radio"
                />
            </td>
            <td className="centered">
                <input
                    id={id}
                    type="radio"
                    value="extra"
                    onChange={(e) => props.onChangeIncluded(e)}
                    name={"included_" + id}
                    checked={props.included === "extra"}
                    className="custom-radio"
                />
            </td>
            <td>
                <InputGroup>
                    <Form.Control
                        id={id}
                        name={name}
                        type="number"
                        step="0.01"
                        placeholder="0.00"
                        value={value}
                        onChange={props.onChangePrice}
                        onBlur={(e) => props.onBlur(e.target.id)}
                        size="sm"

                    />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid price for {name}.
                    </Form.Control.Feedback>
                </InputGroup>
            </td>

            <td>
                <InputGroup>
                    <Form.Control
                        id={id}
                        name={name}
                        type="number"
                        value={order}
                        onChange={props.onChangeOrder}
                        onBlur={(e) => props.onBlur(e.target.id)}
                        size="sm"
                    />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid price for {name}.
                    </Form.Control.Feedback>
                </InputGroup>
            </td>
            <td>
                {onRemove ?
                    <OverlayTrigger
                        placement="left"
                        overlay={
                            <Tooltip> Remove {name} </Tooltip>
                        }
                    >
                        <Button
                            variant="danger"
                            onClick={() => { onRemove(id); }}
                            title="-"
                        >
                            <i className="fas fa-trash-alt" />
                        </Button>
                    </OverlayTrigger>
                    :
                    <OverlayTrigger
                        placement="left"
                        overlay={
                            <Tooltip> Cannot remove {name} </Tooltip>
                        }
                    >
                        <span style={{ opacity: .5 }}>
                            <Button
                                variant="outline-secondary"
                                disabled
                            >
                                <i className="fas fa-trash-alt" />
                            </Button>
                        </span>

                    </OverlayTrigger>
                }
            </td>
        </tr>

    );
} 