import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Enquiries/actions';
import ScreensContainer from './ScreensContainer';
import Toolbar from './EnquiryToolbar';
import SaveDialog from './Dialog/SaveDialog';
import QuoteDialog from '../Quote/QuoteDialog';
import { Tab, Tabs } from 'react-bootstrap';

class Enquiry extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            enquiryId: props.match.params.enquiryId ? props.match.params.enquiryId : null,

            enquiryNumber: "",
            customerName: "",
            contactName: "",
            itemName: "",

            newEnquiry: true,
            unsavedChanges: false,

            promptingSave: false,
            generatingQuote: false,
        };

        this.loadEnquiry();
    }

    loadEnquiry = async () => {
        if (this.state.enquiryId)
            await this.props.getEnquiry(this.state.enquiryId).then(() => {
                this.setState({
                    enquiryNumber: this.props.currentEnquiry.enquiryNumber,
                    customerName: this.props.currentEnquiry.customerName,
                    contactName: this.props.currentEnquiry.contactName,
                    itemName: this.props.currentEnquiry.itemName,
                    newEnquiry: false
                });
            });
        else
            await this.props.getEnquiryNumber().then(() => {
                this.setState({
                    enquiryNumber: this.props.nextEnquiryNumber
                });
            });
    }

    handleUpdate = (e) => {
        this.setState({ [e.target.name]: e.target.value, unsavedChanges: true });
    }

    handleSave = async (closeOnSave) => {

        let model = {
            EnquiryNumber: this.state.enquiryNumber,
            CustomerName: this.state.customerName,
            ContactName: this.state.contactName,
            ItemName: this.state.itemName
        };

        await this.props.saveEnquiry(model).then((enquiryNumber) => {
            this.setState({
                newEnquiry: false,
                unsavedChanges: false,
                enquiryNumber: this.props.currentEnquiry.enquiryNumber
            });
        });
    }

    handleQuote = async () => {
        this.setState({ generatingQuote: true });
    }

    handleClose = (e) => {
        if (this.state.newEnquiry || this.state.unsavedChanges) this.setState({ promptingSave: true });
        else
            this.closeEnquiry();
    }

    closeEnquiry = () => {
        window.location = "/enquiries/";
    }

    handleScreenUpdated() {
        this.setState({ unsavedChanges: true });
    }
    render() {
        return (
            <React.Fragment>
                <Toolbar
                    {...this.state}
                    canGenerateQuote={!this.state.newEnquiry}
                    onUpdate={this.handleUpdate}
                    onGenerateQuote={this.handleQuote}
                    onSave={this.handleSave}
                    onClose={this.handleClose}
                />

                <ScreensContainer onUpdate={() => this.handleScreenUpdated()} />           
                
                {this.state.promptingSave &&
                    <SaveDialog
                        {...this.state}
                        onUpdate={this.handleUpdate}

                        onSave={() => {
                            this.handleSave(true);
                            this.handleClose();
                        }}
                        onClose={this.closeEnquiry}
                        onComplete={() => { this.setState({ promptingSave: false }); }}
                    />
                }

                {this.state.generatingQuote &&
                    <QuoteDialog
                        enquiry={this.state}
                        onComplete={() => { this.setState({ generatingQuote: false }); }}
                    />
                }

            </React.Fragment>
        );
    }
}

export default connect(
    state => state.enquiries,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Enquiry);
