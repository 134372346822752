import React from 'react';
import { Accordion, Alert, Button, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Screen/actions';
import Screen from "./Screen";
import Api from "../Api";
import BuildingScreen from './BulidingScreen';
import ScreenDesignerBreakdown from './ScreenDesignerBreakdown';
import ScreenDesignerDecks from './ScreenDesignerDecks';
import ScreenDetail from "./ScreenDetail";
import SelectedCut from "./SelectedCut";
import AdjustCutDialog from './SelectedCut/AdjustCutDialog';
import QuickScreen from './QuickScreen';

class ScreenDesigner extends React.Component {

    constructor(props) {
        super(props);

        let screenIndex = (props.index) ? props.index : 0;

        this.state = {
            screenIndex: screenIndex,
            selectedDeck: 0,
            selectedCut: 0,
            materials: [],
            adjustDeckDialogOpen: false,
            cutProperties: {
                width: null,
                length: null,
                normalLimit: null,
                ratio: null,
                openAreaActual: null,
                openAreaFactor: null,
                slot: null
            }
        };
    }
    componentDidMount() {
        Api.Screen.getScreenMaterials().then(async (response) => {

            var result = await response.json();

            this.setState({
                materials: result
            });
        });
    }
    handleUnsavedChanges() {
        if (this.props.onUpdate)
            this.props.onUpdate();
    }
    handleScreenOnChange(e) {
        if (e.target.type === "checkbox") {
            this.props.updateScreenProperites(this.state.screenIndex, e.target.name, e.target.checked);
        } else {
            this.props.updateScreenProperites(this.state.screenIndex, e.target.name, e.target.value);
        }
    }
    handleDeckOnChange(deck, cut, e) {
        this.props.updateDeck(this.state.screenIndex, deck, cut, e.target.value);
    }
    handleOnNewDeck() {
        this.props.addNewDeck(this.state.screenIndex);
    }
    handleOnDeleteDeck(index) {
        this.props.removeDeck(this.state.screenIndex, index);
    }
    handleBreakdownOnChange(line, e) {
        this.props.updateBreakdown(this.state.screenIndex, line, e.target.name, e.target.value);
    }
    handleOnNewBreakdownLine() {
        this.props.addBreakdownLine(this.state.screenIndex);
    }
    handleOnDeleteBreakdownLine(index) {
        this.props.removeBreakdownLine(this.state.screenIndex, index);
    }
    handleOnCutSelected(deck, cut) {
        this.setState({ selectedDeck: deck, selectedCut: cut });
    }
    handleOnSelectMaterial(index, e) {
        this.props.updateDeckMaterial(this.state.screenIndex, index, e.target.value);
    }
    handleUpdateCutProperties(e) {

        let foo = Object.assign({}, this.state.cutProperties);
        foo[e.target.name] = e.target.value;

        this.setState({ cutProperties: foo });
    }
    closeDownAdjustCutDialog() {
        this.setState({
            adjustDeckDialogOpen: false,
            cutProperties: {
                width: null,
                length: null,
                normalLimit: null,
                ratio: null,
                openAreaActual: null,
                openAreaFactor: null,
                slot: null
            }
        });
    }
    handleAdjustCutDialogOpen() {
        this.setState({ adjustDeckDialogOpen: true });
    }
    handleAdjustCutCancel() {
        this.closeDownAdjustCutDialog();
    }
    handleAdjustCutOk() {

        let properties = Object.assign({}, this.state.cutProperties);
        this.closeDownAdjustCutDialog();

        this.props.updateCutProps(
            this.state.screenIndex,
            this.state.selectedDeck,
            this.state.selectedCut,
            properties).then(() => {
                this.props.build(this.state.screenIndex);
            }).then(() => this.props.onUpdate());
    }
    handleAdjustCutReset(deck, cut) {
        this.closeDownAdjustCutDialog();

        this.props.resetCutProps(this.state.screenIndex, deck, cut).then(() => {
            this.props.build(this.state.screenIndex);
        });
    }
    handleResetScreen = () => {
        this.props.resetScreen(this.state.screenIndex).then(() => {
            this.handleUnsavedChanges();
        });
    }
    handleBuild = () => {
        this.props.build(this.state.screenIndex).then(() => {
            this.handleUnsavedChanges();
        });
    }
    handleDeleteScreen = () => {
        this.props.onDelete();
    }
    render() {

        const { properties, decks, breakdown, calculatedScreen, screenInvalidated, itemName } = this.props.screens[this.state.screenIndex];

        return (
            <>
                {this.props.building && <BuildingScreen />}

                <div className="alert-container">
                    {this.props.formInvalid &&
                        <Alert className="invalid-form-alert" variant="danger" show>
                            Not all screen details are correct, please review screen options, before building screen.
                        </Alert>
                    }
                    {
                        this.props.buildFailed &&
                        <Alert className="invalid-form-alert" variant="danger" style={{ "text-align": "center" }} show>
                            Calculation Failed ({this.props.exception}) the screen has not been updated. <br /><br />
                            Please review screen options and try again.
                        </Alert>
                    }
                </div>

                <Container fluid>
                    <form name="theForm">
                        <Row>
                            <Col sm={12}>
                                <Row>
                                    <Col md="4">
                                        <Accordion defaultActiveKey="0">
                                            <ScreenDetail properties={properties} itemName={itemName}
                                                onChange={this.handleScreenOnChange.bind(this)}
                                            />
                                            <ScreenDesignerDecks
                                                decks={decks}
                                                materials={this.state.materials}
                                                onNewItem={this.handleOnNewDeck.bind(this)}
                                                onDeleteItem={this.handleOnDeleteDeck.bind(this)}
                                                onSelectMaterial={this.handleOnSelectMaterial.bind(this)}
                                                onChange={this.handleDeckOnChange.bind(this)}
                                            />
                                            <ScreenDesignerBreakdown
                                                breakdown={breakdown}
                                                onNewItem={this.handleOnNewBreakdownLine.bind(this)}
                                                onDeleteItem={this.handleOnDeleteBreakdownLine.bind(this)}
                                                onChange={this.handleBreakdownOnChange.bind(this)}
                                            />
                                        </Accordion>

                                        {calculatedScreen ?
                                            <Button style={{ width: "100%", marginTop: '.5em' }} onClick={this.handleResetScreen}>Reset/Rebuild Screen</Button> :
                                            <Button style={{ width: "100%", marginTop: '.5em' }} onClick={this.handleBuild}>Build Screen</Button>
                                        }
                                        {this.props.totalNumberOfScreens > 1 &&
                                            <Button variant="outline-danger" style={{ width: "100%", marginTop: '.5em' }} onClick={this.handleDeleteScreen}>Delete Screen</Button>
                                        }
                                    </Col>
                                    <Col md="8">
                                        {calculatedScreen ?
                                            <>
                                                <Screen
                                                    {...calculatedScreen}
                                                    properties={properties}
                                                    onCutSelected={this.handleOnCutSelected.bind(this)}
                                                    selectedDeck={this.state.selectedDeck}
                                                    selectedCut={this.state.selectedCut}
                                                    onUpdate={() => this.handleUnsavedChanges()}
                                                    selectedScreenIndex={this.state.screenIndex}
                                                    screenInvalidated={screenInvalidated}
                                                />
                                                <SelectedCut
                                                    {...calculatedScreen.decks[this.state.selectedDeck].cuts[this.state.selectedCut]}
                                                    deck={this.state.selectedDeck}
                                                    cut={this.state.selectedCut}
                                                    adjustCutProperties={this.handleAdjustCutDialogOpen.bind(this)}
                                                    screen={properties}
                                                />
                                                {this.state.adjustDeckDialogOpen &&
                                                    <AdjustCutDialog
                                                        deck={this.state.selectedDeck}
                                                        cut={this.state.selectedCut}
                                                        cutDetails={this.state.cutProperties}
                                                        cutProps={calculatedScreen.decks[this.state.selectedDeck].cuts[this.state.selectedCut]}
                                                        onChange={this.handleUpdateCutProperties.bind(this)}
                                                        onCancel={this.handleAdjustCutCancel.bind(this)}
                                                        onOk={this.handleAdjustCutOk.bind(this)}
                                                        onReset={this.handleAdjustCutReset.bind(this)}
                                                    />
                                                }
                                            </> :
                                            <QuickScreen
                                                selectedScreen={this.state.screenIndex}
                                            />
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </form>
                </Container>
            </>
        );
    }
}

export default connect(
    state => state.screening,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ScreenDesigner);