import Access from '../lib/Access';

export default {

    getNext: () => fetch(`api/Enquiry/GetNextEnquiryNumber`, {
        method: "GET",
        headers: {
            "Authorization": 'Bearer ' + Access.getCachedToken()
        }
    }),

    getEnquiries: () => fetch(`api/Enquiry/GetEnquiries`, {
        method: "GET",
        headers: {
            "Authorization": 'Bearer ' + Access.getCachedToken()
        }
    }),

    getEnquiry: (id) => fetch(`api/Enquiry/GetEnquiry?Id=` + id, {
        method: "GET",
        headers: {
            "Authorization": 'Bearer ' + Access.getCachedToken()
        }
    }),

    saveEnquiry: (options) => fetch(`api/Enquiry/SaveEnquiry`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": 'Bearer ' + Access.getCachedToken()
        },
        body: JSON.stringify(options)
    }),

    updateEnquiry: (options) => fetch(`api/Enquiry/UpdateEnquiry`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": 'Bearer ' + Access.getCachedToken()
        },
        body: JSON.stringify(options)
    }),

    deleteEnquiry: (id) => fetch(`api/Enquiry/DeleteEnquiry?Id=` + id, {
        method: "DELETE",
        headers: {
            "Authorization": 'Bearer ' + Access.getCachedToken()
        },
    }),

};