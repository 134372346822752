import React from 'react';
import {
    Route,
    Redirect
} from "react-router";
import Api from './Api';
import Loading from './Common/Loading';

export default class PrivateRoute extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticating: true,
            isAdmin: false
        };
    }
    componentDidMount() {
        Api.isUserAuthenticated().then(async (response) => {

            var authResult = await response.json();

            Api.isUserInRole("Super User").then(async (response) => {

                var roleResult = await response.json();
                this.setState({
                    isAuthenticated: authResult.isAuthenticated,
                    isAdmin: roleResult.isInRole,
                    isAuthenticating: false
                });

            }).catch(errors => console.log(errors));
        }).catch(errors => console.log(errors));


    }

    render() {
        const { component: Component, ...rest } = this.props;
        return (


            <Route
                {...rest}
                render={props =>
                    this.state.isAuthenticating ?
                        <Loading />
                        :

                        this.state.isAuthenticated ? (<Component userIsAdmin={this.state.isAdmin}  {...props} />)
                            : (
                                <Redirect
                                    to={{
                                        pathname: "/",
                                        state: { from: props.location }
                                    }}
                                />
                            )
                }
            />
        );
    }
}