import React from 'react';
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Loading from '../Common/Loading';
import PriceInput from './PriceInput';

export default function PriceList(props) {
    const currency = '£';

    return (
        <React.Fragment>
            <Table hover striped>
                <thead>
                    <th>{props.label ? props.label : "Optional Extras"}</th>
                    <th style={{ width: "100px" }}>Excluded</th>
                    <th style={{ width: "100px" }}>Included</th>
                    <th style={{ width: "100px" }}>Extra</th>
                    <th style={{ width: "200px" }}>{currency}</th>
                    <OverlayTrigger
                        placement={"bottom"}
                        overlay={
                            <Tooltip>
                                The order that included items are listed in the quote document. Setting to 0 will hide the item on the quote.
                            </Tooltip>
                        }
                    >
                        <th style={{ width: "150px" }}>Quote Order</th>
                    </OverlayTrigger>
                    <th style={{ width: "80px" }} />
                </thead>
                <tbody>
                    {props.quoteItems ?
                        props.quoteItems.map(item => {
                            return (
                                <PriceInput
                                    key={item.id}
                                    id={item.id}
                                    name={item.name}
                                    value={item.value}
                                    included={item.included}
                                    order={item.order}
                                    onChangePrice={props.onChange}
                                    onChangeIncluded={props.onChangeIncluded}
                                    onChangeOrder={props.onChangeOrder}
                                    onBlur={props.onSave}
                                    onRemove={item.canRemove ? props.onRemove : undefined}
                                />
                            );
                        })
                        :
                        <Loading />
                    }
                </tbody>
            </Table>

        </React.Fragment>
    );
}

