import React from 'react';
import { Form } from 'react-bootstrap';

export default function StandardRows(props) {

    return (
        props.items.map(x => {
            return (
                <tr className="cost-row" key={x.id}>
                    <td>{x.name}</td>
                    <td className="centered">
                        <input
                            type="radio"
                            label=""
                            value="excluded"
                            name={"included_" + x.id}
                            checked={x.included === "excluded"}
                            onChange={(e) => props.onUpdate(x.id, e)}
                            className="custom-radio"
                        />
                    </td>
                    <td className="centered">
                        <input
                            type="radio"
                            value="included"
                            onChange={(e) => props.onUpdate(x.id, e)}
                            name={"included_" + x.id}
                            checked={x.included === "included"}
                            className="custom-radio"
                        />
                    </td>
                    <td className="centered">
                        <input
                            type="radio"
                            value="extra"
                            onChange={(e) => props.onUpdate(x.id, e)}
                            name={"included_" + x.id}
                            checked={x.included === "extra"}
                            className="custom-radio"
                        />
                    </td>
                    <td >
                        {
                            x.included === "excluded" ?
                                <small />
                                :
                                <Form.Control
                                    name="qty"
                                    type="number"
                                    step={1}
                                    min={1}
                                    value={x.qty}
                                    onChange={(e) => props.onUpdate(x.id, e)}
                                />
                        }

                    </td>
                    <td>
                        {
                            x.included === "excluded" ?
                                <small />
                                :
                                <Form.Control
                                    name="price"
                                    type="number"
                                    step={0.01}
                                    value={x.price}
                                    onChange={(e) => props.onUpdate(x.id, e)}
                                />
                        }
                    </td>
                    <td>

                        {
                            x.included === "excluded" ?
                                <small />
                                :
                                <Form.Control
                                    name="margin"
                                    type="number"
                                    value={x.margin}
                                    onChange={(e) => props.onUpdate(x.id, e)}
                                />
                        }

                    </td>
                    <td>


                        {
                            x.included === "excluded" ?
                                <Form.Control
                                    type="text"
                                    value={"N/A"}
                                    readOnly
                                />
                                :
                                <Form.Control
                                    type="text"
                                    value={"£" + (x.sellPrice && x.sellPrice.toFixed(2))}
                                    readOnly
                                />
                        }
                    </td>

                </tr>
            );
        })
    );
}