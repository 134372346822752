import {
    RECEIVED_USERS,
    RECEIVED_ROLES,
    SAVING_USER_DATA
} from '../action-types';

const initialState = {
    users: null,
    roles: [],
    saveInProgress: false,
    saveComplete: false
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case RECEIVED_USERS:
            return {
                ...state,
                users: action.users
            };
        case RECEIVED_ROLES:
            return {
                ...state,
                roles: action.roles,
            };
        case SAVING_USER_DATA:
            return {
                ...state,
                saveInProgress: action.inProgress,
                saveComplete: action.complete
            };
        default:
            return state;
    }
};