export default {
    setToken: (token) => {
        window.sessionStorage.setItem("token", token);
    },
    getCachedToken() {
        var token = window.sessionStorage["token"];
        return token ? JSON.parse(token)["access_token"] : token;
    },
    getDisplayName() {
        var token = window.sessionStorage["token"];
        return token ? JSON.parse(token)["display_name"] : token;
    },
}