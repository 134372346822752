
/* SCREEN */
export const SET_SCREEN_STATE = "SET_SCREEN_STATE";

export const UPDATE_SCREEN_OPTIONS = "UPDATE_SCREEN_OPTIONS";
export const UPDATE_WATER_OPTIONS = "UPDATE_WATER_OPTIONS";
export const UPDATE_BEARING_OPTIONS = "UPDATE_BEARING_OPTIONS";

export const ADD_BREAKDOWN = "ADD_BREAKDOWN_LINE";
export const UPDATE_BREAKDOWN = "UPDATE_BREAKDOWN";

export const ADD_DECK = "ADD_DECK";
export const UPDATE_DECK = "UPDATE_DECK";

export const UPDATE_DECK_CUT_PROP = "UPDATE_DECK_CUT_PROP";
export const RESET_DECK_CUT_PROP = "RESET_DECK_CUT_PROP";

export const REMOVE_DECK = "REMOVE_DECK";
export const REMOVE_BREAKDOWN = "REMOVE_BREAKDOWN";

export const BUILDING_SCREEN = "BUILDING_SCREEN";
export const SCREEN_BUILT = "SCREEN_BUILT";
export const SCREEN_DETAIL_INVALID = "SCREEN_DETAIL_INVALID";
export const BUILD_FAILED = "BUILD_FAILED";
export const RESET_SCREEN = "RESET_SCREEN";
export const SCREEN_REBUILT = "SCREEN_REBUILT";

export const ADD_SCREEN = "ADD_SCREEN";
export const DELETE_SCREEN = "DELETE_SCREEN";

/* Users */
export const RECEIVED_USERS = "RECEIVED_USERS";
export const RECEIVED_ROLES = "RECEIVED_ROLES";
export const SAVING_USER_DATA = "SAVING_USER_DATA";

/* Quotes */
export const RECEIVED_QUOTE_ITEMS = "RECEIVED_QUOTE_ITEMS";
export const SAVING_ITEM_PRICE = "SAVING_ITEM_PRICE";
export const GENERATING_QUOTE = "GENERATING_QUOTE";
export const UPDATE_QUOTE_OPTION = "UPDATE_QUOTE_OPTION";
export const UPDATE_QUOTE_SCREEN = "UPDATE_QUOTE_SCREEN";
export const SAVING_PRICEGROUP_DATA = "SAVING_PRICEGROUP_DATA";
export const RECEIVED_QUOTE_TERMS = "RECEIVED_QUOTE_TERMS";

/* Enquiries */
export const RECEIVED_ENQUIRY_NUMBER = "RECEIVED_ENQUIRY_NUMBER";
export const RECEIVED_ENQUIRY = "RECEIVED_ENQUIRY";
export const RECEIVED_ENQUIRIES = "RECEIVED_ENQUIRIES";
export const SAVING_ENQUIRY = "SAVING_ENQUIRY";
export const UPDATE_NUMBER_OF_SCREENS_FOR_ENQUIRY = "UPDATE_NUMBER_OF_SCREENS_FOR_ENQUIRY";





