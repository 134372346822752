import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Users/actions';
import Loading from '../../Common/Loading';

class AddUserDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: true,
            validated: false,
            username: "",
            password: "",
            role: ""
        };

        this.loadUserRoles();
    }

    loadUserRoles() {
        this.props.getRoles().then(() => {
            this.setState({
                role: this.props.roles[0].id
            });
        });
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    handleSave = () => {

        if (this.state.username.length > 0 && this.state.password.length > 0) {
            this.props.addUser({
                username: this.state.username,
                password: this.state.password,
                role: this.state.role
            });
        } else {
            this.setState({ validated: true });
        }
    }

    render() {
        const { onComplete, saveInProgress, saveComplete, roles } = this.props;
        const { open } = this.state;
        return (
            <Modal show={open}
                centered
                onExited={() => {
                    if (onComplete) {
                        this.props.resetUserForms();
                        onComplete();
                    }
                }}
            >
                <Modal.Header>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {saveComplete ?
                        <div style={{ textAlign: "center", padding: '3em' }}>
                            <h4>User was created successfully.</h4>
                        </div>
                        : saveInProgress ?
                            <Loading loadMessage="Adding user" />
                            :
                            <Form
                                noValidate
                                validated={this.state.validated}
                            >
                                <Form.Group>
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control required name="username" value={this.state.username} onChange={this.handleChange} placeholder="Enter username" />
                                    <Form.Control.Feedback type="invalid">
                                        Username is required.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control required name="password" value={this.state.password} onChange={this.handleChange} type="password" placeholder="Password" />
                                    <Form.Control.Feedback type="invalid">
                                        Password is required.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Role</Form.Label>
                                    <Form.Control required name="role" value={this.state.role} onChange={this.handleChange} as="select">
                                        {roles.map(function (role) { return <option key={role.id} value={role.id}>{role.name}</option>; })}
                                    </Form.Control>
                                </Form.Group>
                            </Form>}
                </Modal.Body>
                <Modal.Footer>
                    {
                        saveInProgress || saveComplete ?
                            <React.Fragment>
                                <Button
                                    variant="primary"
                                    onClick={this.handleClose}
                                    disabled={saveInProgress}
                                >
                                    Close
                                </Button>

                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Button variant="secondary" onClick={this.handleClose}>
                                    Cancel
                             </Button>
                                <Button
                                    variant="primary"
                                    onClick={this.handleSave}
                                >
                                    Save
                              </Button>

                            </React.Fragment>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default connect(
    state => state.users,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(AddUserDialog);