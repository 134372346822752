import {
    RECEIVED_QUOTE_ITEMS,
    SAVING_ITEM_PRICE,
    GENERATING_QUOTE,
    UPDATE_QUOTE_OPTION,
    UPDATE_QUOTE_SCREEN,
    SAVING_PRICEGROUP_DATA,
    RECEIVED_QUOTE_TERMS
} from '../action-types';

const initialState = {
    quoteItems: null,
    quoteTerms: null,
    fabricatedWeight: 0,
    initialFeed: 0,
    saveInProgress: false,
    saveComplete: false,
    success: false,
    selectedScreen: 0,
    canGenerateQuote: null,
    quoteExists: null,
    previousQuoteConfig: []
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case RECEIVED_QUOTE_ITEMS:
            return {
                ...state,
                quoteItems: action.quoteItems,
                fabricatedWeight: action.fabricatedWeight,
                initialFeed: action.initialFeed,
                initialLump: action.initialLump,
            };
        case SAVING_ITEM_PRICE:
            return {
                ...state,
                saveInProgress: action.inProgress,
                saveComplete: action.complete
            };
        case GENERATING_QUOTE:
            return {
                ...state,
                success: action.success,
                quoteExists: action.success === true ? true : false
            };
        case UPDATE_QUOTE_OPTION:
            var quoteState = action.config ? JSON.parse(action.config) : {};
            if (quoteState.costs === undefined)
                return {
                    ...state,
                    quoteExists: action.quoteExists,
                    previousQuoteConfig: false
                }
            else return {
                ...state,
                quoteExists: action.quoteExists,
                previousQuoteConfig: quoteState
            };
        case UPDATE_QUOTE_SCREEN:
            return {
                ...state,
                selectedScreen: action.selectedScreen
            };
        case SAVING_PRICEGROUP_DATA:
            return {
                ...state,
                saveInProgress: action.inProgress,
                saveComplete: action.complete
            };

        case RECEIVED_QUOTE_TERMS:
            return {
                ...state,
                quoteTerms: action.quoteTerms
            }

        default:
            return state;
    }
};