import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Users/actions';
import { Button, Row, Col, OverlayTrigger, Tooltip, Tab, Tabs } from 'react-bootstrap';
import UsersTable from './UsersTable';
import AddUserDialog from './Dialog/AddUserDialog';
import ResetPasswordDialog from './Dialog/ResetPasswordDialog';

class UserAdmin extends React.Component {

    constructor(props) {
        super(props);
        props.getUsers();

        this.state = {
            addingUser: false,
            resettingPassword: false,
            selectedUser: null,
        };
    }

    handlePasswordReset = (userId) => {
        this.setState({
            selectedUser: userId,
            resettingPassword: true,
        });
    }

    handleAddUser = () => {
        this.setState({ addingUser: true });
    }

    handleComplete = () => {
        this.setState({
            addingUser: false,
            resettingPassword: false,
            selectedUser: null,
        });
    }

    render() {
        return (
            <React.Fragment>

                <Tabs
                    activeKey="users"
                    className="users-tabs"
                    variant="pills"
                    style={{
                        margin: '2em 0'
                    }}
                >
                    <Tab eventKey="users" title="User Accounts" >
                        <Row>
                            <Col>
                                <UsersTable users={this.props.users} resetPassword={this.handlePasswordReset} />
                            </Col>
                        </Row>
                    </Tab>


                    <Tab eventKey="ADD_USER" title={
                        <OverlayTrigger
                            placement="left"
                            overlay={<Tooltip > Add a new user </Tooltip>}
                        >
                            <span style={{ position: 'absolute' }}>

                                <Button
                                    style={{ margin: '-1em .25em -.8em' }}
                                    variant="primary"
                                    onClick={this.handleAddUser}
                                >
                                    <i className="fas fa-plus" />
                                </Button>
                            </span>
                        </OverlayTrigger>

                    } />
                </Tabs>



                {this.state.addingUser &&
                    <AddUserDialog
                        onComplete={() => { this.handleComplete(); }}
                    />
                }

                {this.state.resettingPassword &&
                    <ResetPasswordDialog
                        onComplete={() => { this.handleComplete(); }}
                        user={this.state.selectedUser}
                    />
                }
            </React.Fragment>
        );
    }
}

export default connect(
    state => state.users,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(UserAdmin);
