import React from 'react';
import { Button, Table, Accordion, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from '../../Common/Loading';

export default function EnquiriesTable(props) {

    return (
        <React.Fragment >
            {props.enquiries ? props.enquiries.map((year, i) => {

                return year.length === 0 ? null : (
                    <div key={i} style={{ margin: '2em' }}>
                        <h5> {new Date(year[0].createdDate).getFullYear()}</h5>
                        <Accordion>
                            {year.map((group, index) => {
                                return (
                                    <Card key={index}>
                                        <Accordion.Toggle as={Card.Header} eventKey={index} >
                                            {group.name}
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey={index}>
                                            <Card.Body as={Table}>
                                                <Table size="sm" responsive striped hover borderless>
                                                    <thead style={{ fontSize: '.75em' }}>
                                                        <tr>
                                                            <th>Enquiry Number</th>
                                                            <th>Customer Name</th>
                                                            <th>Created Date</th>
                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {group.enquiries.map(e => {
                                                            return (
                                                                <tr key={e.id}>
                                                                    <td><Link to={"/enquiry/" + e.id}>{e.enquiryNumber}</Link></td>
                                                                    <td>{e.customerName}</td>
                                                                    <td>{e.createdDateString}</td>
                                                                    <td style={{ textAlign: 'right' }}>
                                                                        {props.allowDelete &&
                                                                            <OverlayTrigger
                                                                                placement="left"
                                                                                overlay={
                                                                                    <Tooltip> Delete enquiry </Tooltip>
                                                                                }
                                                                            >
                                                                                <Button
                                                                                    size="sm"
                                                                                    variant="outline-danger"
                                                                                    onClick={() => { props.onDelete(e.id, e.enquiryNumber); }}>
                                                                                    <i className="fas fa-trash-alt" />
                                                                                </Button>
                                                                            </OverlayTrigger>
                                                                        }
                                                                    </td>

                                                                </tr>
                                                            );
                                                        })}

                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>);
                            })}
                        </Accordion>
                    </div>
                )
            })

                :
                <div style={{ textAlign: 'center' }}> <Loading loadMessage="Loading saved enquiries" /></div>
            }
        </ React.Fragment>
    );
}

