import React from 'react';
import { Button, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function UsersTable(props) {

    return (
        <Table responsive striped hover>
            <thead>
                <tr>
                    <th>Username</th>
                    <th>Role</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {props.users && props.users.map(user => {
                    return (
                        <tr key={user.id}>
                            <td style={{ width: '70%' }}>{user.username}</td>
                            <td>{user.role}</td>
                            <td style={{ textAlign: 'right' }}>
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                        <Tooltip > Reset password </Tooltip>
                                    }
                                >
                                    <Button variant="dark" onClick={() => { props.resetPassword({ id: user.id, name: user.username }); }}><i className="fas fa-key" /></Button>
                                </OverlayTrigger>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}

