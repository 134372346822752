import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';

export default function SelectionRow(props) {

    const { category, prepend, label, optionalExtras } = props;
    const options = optionalExtras.filter(extra => extra.category === category);
    const selected = options.filter(x => x.selected).length > 0 ? options.filter(x => x.selected)[0] : options[0];

    useEffect(() => {
        if (selected)
            props.onUpdateSelection(category, { target: { value: selected.name } });

    }, [optionalExtras && optionalExtras.length > 0]);

    return (
        selected ?
            <tr className="cost-row">
                <td>
                    {prepend && prepend + " "}
                    <select
                        value={selected.name}
                        onChange={(e) => { props.onUpdateSelection(category, e); }}
                    >
                        {options && options.map(x => {
                            return (
                                <option key={x.id} value={x.name}>{x.name}</option>
                            );
                        })
                        }
                    </select>
                    {label && " " + label}
                </td>


                <td className="centered">
                    <input
                        type="radio"
                        label=""
                        value="excluded"
                        name={"included_" + selected.id}
                        checked={selected.included === "excluded"}
                        onChange={(e) => props.onUpdate(selected.id, e)}
                        className="custom-radio"
                        disabled={props.hideIncluded}
                    />
                </td>
                <td className="centered">
                    <input
                        type="radio"
                        value="included"
                        onChange={(e) => props.onUpdate(selected.id, e)}
                        name={"included_" + selected.id}
                        checked={selected.included === "included"}
                        className="custom-radio"
                        disabled={props.hideIncluded}

                    />
                </td>
                <td className="centered">
                    <input
                        type="radio"
                        value="extra"
                        onChange={(e) => props.onUpdate(selected.id, e)}
                        name={"included_" + selected.id}
                        checked={selected.included === "extra"}
                        className="custom-radio"
                        disabled={props.hideIncluded}

                    />
                </td>


                <td >
                    {
                        selected.included === "excluded" ?
                            <small />
                            :
                            <Form.Control
                                name="qty"
                                type="number"
                                step={1}
                                min={1}
                                value={selected.qty}
                                onChange={(e) => props.onUpdate(selected.id, e)}
                            />
                    }

                </td>
                <td>
                    {
                        selected.included === "excluded" ?
                            <small />
                            :
                            <Form.Control
                                name="price"
                                type="number"
                                step={0.01}
                                value={selected.price}
                                onChange={(e) => props.onUpdate(selected.id, e)}
                            />
                    }
                </td>
                <td>

                    {
                        selected.included === "excluded" ?
                            <small />
                            :
                            <Form.Control
                                name="margin"
                                type="number"
                                value={selected.margin}
                                onChange={(e) => props.onUpdate(selected.id, e)}
                            />
                    }

                </td>
                <td>


                    {
                        selected.included === "excluded" ?
                            <Form.Control
                                type="text"
                                value={"N/A"}
                                readOnly
                            />
                            :
                            <Form.Control
                                type="text"
                                value={"£" + (selected.sellPrice && selected.sellPrice.toFixed(2))}
                                readOnly
                            />
                    }
                </td>
            </tr>
            : <tr />
    );
}