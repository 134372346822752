import React from 'react';
import { Row, Col, Button, Modal, InputGroup, Form } from 'react-bootstrap';

function FeedboxWeightDialog(props) {

    const { bearingLife } = props;

    return (
        <Modal show>
            <Modal.Header>
                <Modal.Title>Feedbox Properties</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Row}>
                    <Form.Label column >Feedbox Liner Material</Form.Label>
                    <Col>
                        <Form.Control as="select"
                            name="feedboxMaterial"
                            onChange={(e) => { props.onUpdate(e); }}
                            value={bearingLife.feedboxMaterial}
                        >
                            <option value="MildSteel">Mild Steel</option>
                            <option value="ARSteel">A. R. Steel</option>
                            <option value="Hardox400">Hardox 400</option>
                            <option value="Rubber">Rubber</option>
                            <option value="Polyurethane">Polyurethane</option>
                        </Form.Control>
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column>Feedbox Length</Form.Label>
                    <Col>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>mm</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="number"
                                name="feedboxLength"
                                onChange={(e) => { props.onUpdate(e); }}
                                value={bearingLife.feedboxLength}
                            />
                        </InputGroup>
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column>Feedbox Liner Depth</Form.Label>
                    <Col>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>mm</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="number"
                                name="feedboxLinerDepth"
                                onChange={(e) => { props.onUpdate(e); }}
                                value={bearingLife.feedboxLinerDepth}
                            />
                        </InputGroup>
                    </Col>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default FeedboxWeightDialog;