import React from 'react';
import { Spinner } from 'react-bootstrap';

const BulidingScreen = () => (
    <>
        <div className="loading-overlay" />
        <div className="loading-wrapper">            
            <Spinner animation="border" variant="primary" className="loading-spinner" />
            <div className="loading-text">Building Screen, please wait</div>

        </div>
    </>
);

export default BulidingScreen;