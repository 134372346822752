import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../store/Screen/actions';
import { Row, Col, Form } from 'react-bootstrap';
import QuoteScreenSelector from '../ScreenSelector';


export class QuoteSpecificationStage extends React.Component {

    constructor(props) {
        super(props);

        this.mechanisms = ["Vibrex", "Eliptex"];
        this.lubricants = ["Oil", "Grease"];

        this.state = this.init();

        this.props.onUpdate(this.state);
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedScreen !== prevProps.selectedScreen) {

            let data = this.props.specification[this.props.selectedScreen];

            if (data) {
                this.setState({
                    material: data.material,
                    maxFeed: data.maxFeed,
                    maxLump: data.maxLump,
                    drive: data.drive,
                    mountingStyle: data.mountingStyle,
                    mechanism: data.mechanism,
                    lubricant: data.lubricant,
                    motorbase: data.motorbase
                });
            } else this.setState(this.init());
        }
    }

    init = () => {

        if (this.props.previousQuoteConfig && this.props.previousQuoteConfig.specification) {

            let currentSpecification = this.props.previousQuoteConfig.specification[this.props.selectedScreen];
            if (currentSpecification)
                return currentSpecification;
        }

        let screen = this.props.screens[this.props.selectedScreen];

        return {
            material: screen.properties.material,
            maxFeed: screen.properties.feed,
            maxLump: screen.breakdown[0].mm,
            drive: "V-belt drive",
            mountingStyle: "Coil Springs",
            mechanism: this.mechanisms[screen.properties.angleType],
            lubricant: this.lubricants[0],
            motorbase: "Pivoting Base"
        };

    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value },
            () => this.props.onUpdate(this.state)
        );
    }

    render() {
        return (
            <React.Fragment>
                <QuoteScreenSelector screens={this.props.screens} />

                <Row className="quote-dialog-row">
                    <Col xs={6}>
                        <Form.Label>Material</Form.Label>
                        <Form.Control
                            placeholder="e.g. Stone"
                            onChange={this.handleChange}
                            name="material"
                            value={this.state.material}
                        />
                    </Col>


                    <Col>
                        <Form.Label>Maximum Feed (tph)</Form.Label>
                        <Form.Control
                            type="number"
                            onChange={this.handleChange}
                            name="maxFeed"
                            min={0}
                            value={this.state.maxFeed}
                        />
                    </Col>

                    <Col>
                        <Form.Label>Maximum Lump Size (mm)</Form.Label>
                        <Form.Control
                            type="number"
                            onChange={this.handleChange}
                            name="maxLump"
                            min={0}
                            value={this.state.maxLump}
                        />
                    </Col>
                </Row>


                <Row className="quote-dialog-row">
                    <Col xs={6}>
                        <Form.Label>Drive</Form.Label>
                        <Form.Control

                            onChange={this.handleChange}
                            name="drive"
                            value={this.state.drive}
                        />
                    </Col>

                    <Col xs={6}>
                        <Form.Label>Mounting Style</Form.Label>
                        <Form.Control

                            onChange={this.handleChange}
                            name="mountingStyle"
                            value={this.state.mountingStyle}
                        />
                    </Col>
                </Row>
                <Row className="quote-dialog-row">
                    <Col xs={6}>
                        <Form.Label>Motorbase</Form.Label>
                        <Form.Control

                            onChange={this.handleChange}
                            name="motorbase"
                            value={this.state.motorbase}
                        />
                    </Col>

                    <Col xs={3}>
                        <Form.Label>Eliptex / Vibrex</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={this.handleChange}
                            name="mechanism"
                            value={this.state.mechanism}
                        >
                            {
                                this.mechanisms && this.mechanisms.map((x, index) => {
                                    return (
                                        <option key={index} value={x}>{x}</option>
                                    );
                                })
                            }
                        </Form.Control>
                    </Col>

                    <Col xs={3}>
                        <Form.Label>Lubricant</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={this.handleChange}
                            name="lubricant"
                            value={this.state.lubricant}
                        >
                            {
                                this.lubricants && this.lubricants.map((x, index) => {
                                    return (
                                        <option key={index} value={x}>{x}</option>
                                    );
                                })
                            }
                        </Form.Control>
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}

export default connect(
    state => state.screening,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(QuoteSpecificationStage);
