import Api from '../../Api';
import {
    RECEIVED_ENQUIRY_NUMBER,
    RECEIVED_ENQUIRY,
    RECEIVED_ENQUIRIES,
    SAVING_ENQUIRY,    
    SET_SCREEN_STATE // Bit of cross cutting :0,    
} from '../action-types';

const loadEnquiries = async (dispatch) => {
    await Api.Enquiry.getEnquiries().then(async (response) => {
        var result = await response.json();
        dispatch({
            type: RECEIVED_ENQUIRIES,
            enquiries: result.enquiries
        });
    });
};

export const actionCreators = {

    getEnquiryNumber: () => async (dispatch) => {
        await Api.Enquiry.getNext().then(async (response) => {
            var result = await response.json();

            if (result.ok) {
                dispatch({ type: RECEIVED_ENQUIRY_NUMBER, nextEnquiryNumber: result.enquiryNumber });
            }
        });
    },
    getEnquiry: (id) => async (dispatch) => {

        await Api.Enquiry.getEnquiry(id).then(async (response) => {
            var result = await response.json();

            if (result.success) {
                // Bit of cross cutting :0
                let screens = JSON.parse(result.enquiry.screens);
               
                if (Array.isArray(screens)) {

                    for (var i = 0; i < screens.length; i++) {
                        if (screens[i].itemName === undefined)
                            screens[i].itemName = "New Screen";
                    }

                    dispatch({ type: SET_SCREEN_STATE, screens: screens });                    
                } else {
                    if (screens.itemName === undefined)
                        screens.itemName = "New Screen";

                    dispatch({ type: SET_SCREEN_STATE, screens: [{ ...screens }] });
                }
                
                dispatch({ type: RECEIVED_ENQUIRY, enquiry: result.enquiry });
            }
        });
    },
    getEnquiries: () => async (dispatch) => {
        loadEnquiries(dispatch);
    },
    saveEnquiry: (itemModel) => async (dispatch, getState) => {

        dispatch({ type: SAVING_ENQUIRY, inProgress: true, complete: false });

        itemModel["screens"] = JSON.stringify(getState().screening.screens);

        await Api.Enquiry.saveEnquiry(itemModel).then(async (response) => {
            var result = await response.json();
            if (result.success) {
                loadEnquiries(dispatch);

                window.location.href = "/enquiry/" + result.id
            }
        });
        dispatch({ type: SAVING_ENQUIRY, inProgress: false, complete: true });
    },
    updateEnquiry: (itemModel) => async (dispatch, getState) => {

        itemModel["screens"] = JSON.stringify(getState().screening.screens);

        dispatch({ type: SAVING_ENQUIRY, inProgress: true, complete: false });

        await Api.Enquiry.updateEnquiry(itemModel).then(async (response) => {
            var result = await response.json();
            if (result.success) {
                loadEnquiries(dispatch);
            }
        });

        dispatch({ type: SAVING_ENQUIRY, inProgress: false, complete: true });
    },
    deleteEnquiry: (id) => async (dispatch) => {

        await Api.Enquiry.deleteEnquiry(id).then(async (response) => {
            var result = await response.json();
            if (result.success) {
                loadEnquiries(dispatch);
            }
        });
    },    
};