import React from 'react';
import { Spinner } from 'react-bootstrap';

function Loading(props) {
    const { loadMessage } = props;
    return (
        <div style={{ margin: 10, textAlign: "center" }}>
            {loadMessage && <h4>{loadMessage}</h4>}
            <Spinner animation="border" variant="primary" />
        </div>
    );
}

export default (Loading);