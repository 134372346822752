import React from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import SelectionRow from './SelectionRow';
import StandardRows from './StandardRows';


export function OptionalExtrasShoppingList(props) {

    const { optionalExtras } = props;
    const extra = optionalExtras.filter(extra => extra.category === "EXTRA");
    const pullies = extra.splice(1, 2);
    const extra_1 = extra.splice(0, 1);
    const spring_protection = extra.splice(5, 1);
    const bolt = extra.splice(4, 1);
    const countweights = extra.splice(4, 1);
    const rubber_lagging_vibrator_tube = extra.splice(4, 1);
    const grinding = extra.splice(4, 1);
    const delivery = extra.splice(9, 1);
    const pivoting_motor_base = extra.splice(0, 1);
    const cmp = extra.splice(2, 1);
    const friction_checks = extra.splice(2, 1);
    const spray_bars = extra.splice(2, 1);
    const motor_beam = extra.splice(5, 1);
    const installation = optionalExtras.filter(extra => extra.category === "INSTALLATION");

    const defaultCategories = ["DRIVE", "EXTRA", "FEEDL", "INSTALLATION", "LIPL", "MOTOR", "PAINT", "SHAFT", "SHOCK", "VIBRO"];
    const nondefault = optionalExtras
        .filter(extra => !defaultCategories.includes(extra.category))
        .map(obj => obj.category)
        .filter((category, index, self) => self.indexOf(category) === index);

    return (
        <Row className="quote-dialog-row" >
            <Col>
                <Table borderless size="sm">
                    <thead>
                        <tr className="quote-extras-table-heading">
                            <th />
                            <th className="default-th centered">Excluded</th>
                            <th className="default-th centered">Included</th>
                            <th className="default-th centered">Extra</th>
                            <th className="default-th centered">Qty.</th>
                            <th className="wider-th">Cost price</th>
                            <th className="default-th">Margin(%)</th>
                            <th className="wider-th">Sell price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <SelectionRow category="DRIVE" {...props} />
                        <StandardRows items={pullies} {...props} />
                        <StandardRows items={bolt} {...props} />
                        <SelectionRow category="SHOCK" {...props} />
                        <StandardRows items={extra_1} {...props} />
                        <StandardRows items={spring_protection} {...props} />
                        <SelectionRow category="VIBRO" label={"Vibrator Unit"} hideIncluded {...props} />
                        <StandardRows items={motor_beam} {...props} />
                        <StandardRows items={countweights} {...props} />
                        <StandardRows items={rubber_lagging_vibrator_tube} {...props} />
                        <SelectionRow category="SHAFT" {...props} />
                        <StandardRows items={grinding} {...props} />
                        <SelectionRow category="FEEDL" prepend={"Feedbox with"} label={"liners"} {...props} />
                        <SelectionRow category="LIPL" prepend={"Discharge Lips with"} label={"liners"} {...props} />
                        <StandardRows items={delivery} {...props} />
                        <SelectionRow category="MOTOR" label={"kw Motor"}  {...props} />
                        <StandardRows items={pivoting_motor_base} {...props} />
                        <StandardRows items={cmp} {...props} />
                        <StandardRows items={friction_checks} {...props} />
                        <StandardRows items={spray_bars} {...props} />
                        <SelectionRow category="PAINT" {...props} />
                        <StandardRows items={extra} {...props} />
                        <StandardRows items={installation} {...props} />


                        {nondefault.map((x, i) => {
                            return <SelectionRow key={i + x} category={x} label={x} {...props} />
                        })}


                    </tbody>
                </Table>
            </Col>
        </Row >
    );
}

export default (OptionalExtrasShoppingList);