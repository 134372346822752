import React from 'react';
import { Button, Form, Container, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import Api from '../Api';
import Access from '../lib/Access';
import Loading from '../Common/Loading';
import logo from '../assets/img/hri-logo-sm-23.jpg';


class SignIn extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            username: "",
            password: "",
            validated: false,
            loginFailed: false,
        };

        Api.isUserAuthenticated().then(async (response) => {
            var result = await response.json();

            if (result.isAuthenticated) window.location = "/enquiries";
            else this.setState({ isLoading: false });
        }).catch(error => {
            this.setState({ isLoading: false });
        });
    }
    handleErrors = response => {
        if (!response.ok) {

            throw Error(response.statusText);
        }
        return response;
    }
    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleSubmit = (event) => {
        this.setState({ loginFailed: false });
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            this.setState({ validated: true });
            event.preventDefault();
            event.stopPropagation();
        } else {
            Api.signIn({ username: this.state.username, password: this.state.password }).then(async (response) => {

                const token = await response.json();

                console.log(token);

                if (token["access_token"] && token["id_token"]) {
                    Access.setToken(JSON.stringify(token));
                    window.location = '/enquiries';
                }
                else this.setState({ loginFailed: true });
            });

            event.preventDefault();
        }

    };
    render() {

        const { isLoading, loginFailed, validated } = this.state;
        return (

            isLoading ? <Loading /> :

                <Container>
                    <Form className="form-signin"
                        noValidate
                        validated={validated}
                        onSubmit={e => this.handleSubmit(e)}
                    >
                        <img
                            alt=""
                            src={logo}
                            className="d-inline-block align-top login-logo"
                        />
                        <h5>Screening Estimator</h5>
                        <Form.Group controlId="formHorizontalUsername">
                            <Form.Control type="text" name="username" value={this.state.username} onChange={this.handleChange} placeholder="Username" />
                            <Form.Control.Feedback type="invalid">
                                Please enter a username.
                            </Form.Control.Feedback>
                            <Form.Control type="password" name="password" value={this.state.password} onChange={this.handleChange} placeholder="Password" />
                            <Form.Control.Feedback type="invalid">
                                Please enter a password.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button block type="submit" onClick={(event) => this.handleSubmit(event)}>Login</Button>
                    </Form>

                    <Alert className="login-failed-alert"
                        show={loginFailed}
                        variant="danger"
                    >
                        Username or password incorrect. Please try again.
                    </Alert>
                </Container>
        );
    }

}

export default connect()(SignIn);
