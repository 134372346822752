import React from 'react';
import { Row, Col, Button, Modal, InputGroup, Form } from 'react-bootstrap';

function AdjustCutDialog(props) {

    const { cut, deck } = props;
    const normalLimit = props.cutDetails.normalLimit != null ? props.cutDetails.normalLimit : Number(props.cutProps.normalLimit).toFixed(2);   
    const ratio = props.cutDetails.ratio != null ? props.cutDetails.ratio : Number(props.cutProps.ratio).toFixed(2);   
    const openAreaActual = props.cutDetails.openAreaActual != null ? props.cutDetails.openAreaActual : props.cutProps.openAreaActual;
    const openAreaFactor = props.cutDetails.openAreaFactor != null ? props.cutDetails.openAreaFactor : props.cutProps.factors.openAreaFactor;
    const slot = props.cutDetails.slot != null ? props.cutDetails.slot : props.cutProps.slot;
    const efficiency = props.cutDetails.efficiency != null ? props.cutDetails.efficiency : props.cutProps.efficiency;

    return (
        <Modal show>
            <Modal.Header>
                <Modal.Title>Adjust Cut for Deck {deck + 1}, Cut {cut + 1}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>Normal Limit</Col>
                    <Col>
                        <InputGroup>
                            <Form.Control type="text" name="normalLimit" onChange={props.onChange} value={normalLimit} />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>Ratio</Col>
                    <Col>
                        <InputGroup>
                            <Form.Control type="text" name="ratio" onChange={props.onChange} value={ratio} />
                        </InputGroup>
                    </Col>
                </Row>      

                <Row>
                    <Col>Open Area</Col>
                    <Col>
                        <InputGroup>
                            <Form.Control type="text" name="openAreaActual" onChange={props.onChange} value={openAreaActual} />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>Open Factor</Col>
                    <Col>
                        <InputGroup>
                            <Form.Control type="text" name="openAreaFactor" onChange={props.onChange} value={openAreaFactor} />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>Slot Length</Col>
                    <Col>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>mm</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="text" name="slot" onChange={props.onChange} value={slot} />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>Efficiency</Col>
                    <Col>
                        <InputGroup>                           
                            <Form.Control type="text" name="efficiency" onChange={props.onChange} value={efficiency} />
                        </InputGroup>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button style={{ width: "120px" }} onClick={props.onReset.bind(null, deck, cut)}>Reset Cut</Button>
                <Button style={{ width: "90px" }} onClick={props.onCancel}>Cancel</Button>
                <Button style={{ width: "90px" }} onClick={props.onOk}>OK</Button>
            </Modal.Footer>
        </Modal>        
        );
}

export default AdjustCutDialog;