import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loading from '../../Common/Loading';
import { actionCreators } from '../../store/Quotes/actions';


const AddPriceGroupDialog = (props) => {

    const { onComplete, saveInProgress, saveComplete, addPriceGroup, resetPriceForms, category, } = props;
    const [open, setOpen] = useState(true);
    const [validated, setValidated] = useState(false);

    const [groupName, setGroupName] = useState(category ? category[2] : "");
    const [name, setName] = useState("");
    const [value, setValue] = useState(0);

    const handleSave = () => {
        if (groupName.trim().length > 0 && name.trim().length > 0)
            addPriceGroup({
                groupName,
                name,
                value
            });
        else
            setValidated(true);
    }

    return (
        <Modal show={open}
            centered
            onExited={() => {
                if (onComplete) {
                    resetPriceForms();
                    onComplete();
                }
            }}
        >
            <Modal.Header>
                <Modal.Title>{"Add " + (category ? category[1] : "a Price Category")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {saveComplete ?
                    <div style={{ textAlign: "center", padding: '3em' }}>
                        <h4> {(category ? category[1] : "Price category")} was created successfully.</h4>
                    </div>
                    : saveInProgress ?
                        <Loading loadMessage={"Adding price " + (category ? "" : "category")} />
                        :
                        <Form
                            noValidate
                            validated={validated}
                        >
                            <Form.Group>
                                <Form.Label>Category Name</Form.Label>
                                {category ? <p><strong>{category[0]}</strong></p> : <>
                                    <Form.Control
                                        required
                                        minLength={1}
                                        name="groupname"
                                        value={groupName}
                                        onChange={(e) => { setGroupName(e.target.value) }}
                                        placeholder="Enter a category name"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Name is required.
                                    </Form.Control.Feedback>
                                </>

                                }


                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{(category ? category[1] : "Option") + " Name"}</Form.Label>
                                <Form.Control
                                    required
                                    minLength={1}
                                    name="optionname"
                                    value={name}
                                    onChange={(e) => { setName(e.target.value) }}
                                    placeholder="Enter an option name"
                                />
                                <Form.Control.Feedback type="invalid">
                                    You must add an option when creating a new category.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Option Price (£)</Form.Label>
                                <Form.Control
                                    id={name + "_price"}
                                    name={name}
                                    type="number"
                                    step="0.01"
                                    placeholder="0.00"
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid price for {name}.
                                </Form.Control.Feedback>
                            </Form.Group>

                        </Form>}
            </Modal.Body>
            <Modal.Footer>
                {
                    saveInProgress || saveComplete ?
                        <React.Fragment>
                            <Button
                                variant="primary"
                                onClick={() => setOpen(false)}
                                disabled={saveInProgress}
                            >
                                Close
                            </Button>

                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Button variant="secondary" onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onClick={handleSave}
                            >
                                Save
                            </Button>

                        </React.Fragment>
                }
            </Modal.Footer>
        </Modal>
    );
}

export default connect(
    state => state.quotes,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(AddPriceGroupDialog);