import React, { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Row, Card, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Screen/actions';

function QuickScreen(props) {

    const p = props.screens[props.selectedScreen].properties;
    const length = p.UserDefinedLength;
    const width = p.UserDefinedWidth;

    const onUpdate = (e) => {

        var valueMM = getMMValue(e.target.value)

        props.updateScreenProperites(props.selectedScreen, e.target.name, Math.round(valueMM));
    };


    const getMMValue = (value) => {
        return metric ? value : (value * 304.8);
    }

    const getProportionStyle = () => {

        var style = {}
        var max = 320; //maximum pixels

        var l = length ? length : 0;
        var w = width ? width : 0;
        var incomplete = l === 0 || w === 0 || isNaN(l / w);

        if (incomplete) {
            l = 4000;
            w = 1600;
        }

        var ratio = l / w;

        if (previewRatio !== ratio)
            setPreviewRatio(ratio);

        if (showLabel != incomplete)
            setShowLabel(incomplete);

        if (l > w)
            style = {
                width: max + "px",
                height: (max / ratio) + "px"
            }
        else style = {
            width: (max * ratio) + "px",
            height: max + "px"
        }

        //add additional styles
        style.border = "2px solid black";

        return style;
    }

    const getDynamicMargin = () => {
        let m = Math.round(previewRatio / 2 + 2);

        return ((m > 10 ? 10 : m) + "em");
    }


    const getParticles = () => {
        let parts = [];
        for (let i = 0; i < 500; i++) {
            parts.push(<div className="particle"></div >)
        }
        return <div style={{
            position: 'absolute',
            width: '280px',
            height: '96%',
            overflow: 'hidden',
            zIndex:1
        }}>{parts}</div>;
    }

    const previewStyle = {
        wrapper: {
            padding: "2em",
            display: "flex",
            justifyContent: "center"
        },
        screen: {
            display: "flex",
            opacity: (width && length) ? 1 : 0.1,
            transform: "rotateX(51deg) rotateZ(43deg)",
            transformStyle: "preserve-3d",

            boxShadow: `1px 1px 0 1px #f9f9fb,
                        -1px 0 28px 0 rgba(34, 33, 81, 0.01),
                        28px 28px 28px 0 rgba(34, 33, 81, 0.25)`,



        },
        edge: {
            width: "100%",
            height: "10%",
            backgroundColor: "#bbb",
            border: "0px 1px 0px 1px solid black",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
        },
        deck: {
            width: "100%",
            height: "80%",
            backgroundColor: "white",
            opacity: "1",
            backgroundImage: "linear-gradient(#074a91 2px, transparent 2px), linear-gradient(90deg, #074a91 2px, transparent 2px), linear-gradient(#074a91 1px, transparent 1px), linear-gradient(90deg, #074a91 1px, #e5e5f7 1px)",
            backgroundSize: "50px 50px, 50px 50px, 10px 10px, 10px 10px",
            backgroundPosition: "-2px -2px, -2px -2px, -1px -1px, -1px -1px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            animation: (width && length) ? "move-bg .4s linear infinite" : ""
        },
        end: {
            height: "100%",
            border: "4px solid rgba(0,0,0,0.3)",
            backgroundColor: "rgba(0,0,0,0.3)"
        },
        label: {
            opacity: "0.8",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            fontWeight: "bold"
        },
    }

    const [metric, setMetric] = useState(true);
    const [showLabel, setShowLabel] = useState(!(length || width));
    const [showWarning, setShowWarning] = useState(true);
    const [previewRatio, setPreviewRatio] = useState(1);

    useEffect(() => {


    }, [previewRatio]);

    return (
        <>
            <Card >
                <Card.Header>Quick Builder</Card.Header>
                <Card.Body>
                    <Row>
                        <Col sm={{ span: 3, offset: 3 }}  >
                            <label>Width</label>
                            <InputGroup>
                                <Form.Control
                                    inline="true"
                                    name="UserDefinedWidth"
                                    onChange={(e) => { onUpdate(e); }}
                                    value={metric ? width : (Math.round((width / 304.8) * 100) / 100)}
                                    type="number"
                                />
                                <InputGroup.Append className="clickable" onClick={() => setMetric(!metric)}>
                                    <InputGroup.Text>{metric ? "mm" : "ft"}</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>

                        <Col sm={3}>
                            <label>Length</label>
                            <InputGroup>
                                <Form.Control
                                    inline="true"
                                    name="UserDefinedLength"
                                    onChange={(e) => { onUpdate(e); }}
                                    value={metric ? length : (Math.round((length / 304.8) * 100) / 100)}
                                    type="number"
                                />
                                <InputGroup.Append className="clickable" onClick={() => setMetric(!metric)}>
                                    <InputGroup.Text>{metric ? "mm" : "ft"}</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col style={previewStyle.wrapper} >


                            {!showLabel && getParticles()}

                            {showLabel && <p style={previewStyle.label}>Enter a width and height to preview.</p>}
                            <div style={{ ...previewStyle.screen, margin: getDynamicMargin() }}>
                                <div style={{
                                    ...previewStyle.end,
                                    width: "6%",
                                    transform: "perspective(10px) rotateY(-1.8deg) scale(0.96) translateX(2px)"
                                }} ></div>
                                <div style={getProportionStyle()}>
                                    <div style={previewStyle.edge}></div>
                                    <div style={previewStyle.deck}></div>
                                    <div style={previewStyle.edge}></div>
                                </div>

                            </div>
                          
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {
                showWarning &&
                <Alert
                    variant="warning"
                    style={{ marginTop: "1em" }}
                    onClose={() => setShowWarning(false)}
                    dismissible
                >
                    For a more accurate screen calculation, complete the details and grading first and then select <strong>Build Screen</strong>.
                </Alert>
            }
        </>
    );
}

export default connect(
    state => state.screening,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(QuickScreen);

