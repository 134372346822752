import Access from '../lib/Access';

export default {


    generateQuote: (options) => fetch(`api/Quote/Generate`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": 'Bearer ' + Access.getCachedToken()
        },
        body: JSON.stringify(options)
    }),

    downloadQuote: (id) => fetch(`api/Quote/Download?Id=` + id, {
        method: "GET",
        headers: {
            "Authorization": 'Bearer ' + Access.getCachedToken()
        },
    }),

    downloadCosts: (id) => fetch(`api/Quote/DownloadCosts?Id=` + id, {
        method: "GET",
        headers: {
            "Authorization": 'Bearer ' + Access.getCachedToken()
        },
    }),

    existsForEnquiry: (id) => fetch(`api/Quote/Exists?EnquiryId=` + id, {
        method: "GET",
        headers: {
            "Authorization": 'Bearer ' + Access.getCachedToken()
        },
    }),

    getQuoteItems: () => fetch(`api/QuoteItem/GetQuoteItems`, {
        method: "GET",
        headers: {
            "Authorization": 'Bearer ' + Access.getCachedToken()
        }
    }),

    updateQuoteItem: (options) => fetch(`api/QuoteItem/UpdateItem`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": 'Bearer ' + Access.getCachedToken()
        },
        body: JSON.stringify(options)
    }),

    addQuoteItem: (options) => fetch(`api/QuoteItem/AddQuoteItem`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": 'Bearer ' + Access.getCachedToken()
        },
        body: JSON.stringify(options)
    }),

    removeQuoteItem: (options) => fetch(`api/QuoteItem/RemoveQuoteItem`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": 'Bearer ' + Access.getCachedToken()
        },
        body: JSON.stringify(options)
    }),

    getQuoteTerms: () => fetch(`api/QuoteTerm/GetQuoteTerms`, {
        method: "GET",
        headers: {
            "Authorization": 'Bearer ' + Access.getCachedToken()
        }
    }),
    addQuoteTerm: (options) => fetch(`api/QuoteTerm/AddQuoteTerm`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": 'Bearer ' + Access.getCachedToken()
        },
        body: JSON.stringify(options)
    }),
    updateQuoteTerm: (options) => fetch(`api/QuoteTerm/UpdateQuoteTerm`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": 'Bearer ' + Access.getCachedToken()
        },
        body: JSON.stringify(options)
    }),
    removeQuoteTerm: (options) => fetch(`api/QuoteTerm/RemoveQuoteTerm`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": 'Bearer ' + Access.getCachedToken()
        },
        body: JSON.stringify(options)
    }),

};