import React from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loading from '../Common/Loading';
import { actionCreators } from '../store/Quotes/actions';
import QuoteCostsStage from './Stage/Costs';
import QuoteSpecificationStage from './Stage/Specification';
import CommercialTermsStage from './Stage/Terms';

class QuoteDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: true,
            validated: false,
            saveInProgress: false,
            saveComplete: false,

            enquiry: props.enquiry,
            costs: [],
            duty: [],
            specification: [],
            terms: []
        };
    }

    componentDidMount() {
        if (this.props.previousQuoteConfig) {
            this.setState({ ...this.props.previousQuoteConfig })
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    handleSave = () => {

        this.setState({ saveInProgress: true });

        this.props.generateQuote({ ...this.state }).then(() => {
            this.setState({ saveInProgress: false, saveComplete: true });
        });
    }

    onUpdateCosts = (costs) => {

        let costArray = Object.assign([], this.state.costs);
        let current = this.props.selectedScreen;

        costArray[current] = costs;

        this.setState({ costs: costArray });
    }


    onUpdateSpecification = (specification) => {

        let specArray = Object.assign([], this.state.specification);
        let current = this.props.selectedScreen;

        specArray[current] = specification;

        this.setState({ specification: specArray });
    }

    onUpdateTerms = (terms) => {
        this.setState({ terms });
    }

    render() {
        const { onComplete, success } = this.props;
        const { open, saveInProgress, saveComplete } = this.state;

        return (
            <Modal show={open}
                centered
                onExited={() => {
                    if (onComplete) {
                        onComplete();
                    }
                }}
                size="xl"
            >
                <Modal.Header>
                    <Modal.Title>Generate Quote</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {saveComplete ?
                        <div style={{ textAlign: "center", padding: '3em' }}>
                            {success ? <h4>Quote created successfully.</h4> :
                                <>
                                    <h4>Error creating quote</h4>
                                    <p>please check the quote details and try again.</p>
                                </>
                            }
                        </div>
                        : saveInProgress ?
                            <Loading loadMessage="Generating Quote" />
                            :
                            <Tabs defaultActiveKey="costs" className="quote-tabs">
                                <Tab eventKey="costs" title="Costs">
                                    <QuoteCostsStage onUpdate={(costs) => this.onUpdateCosts(costs)} {...this.props} {...this.state} />
                                </Tab>

                                <Tab eventKey="spec" title="Specification Details">
                                    <QuoteSpecificationStage onUpdate={(spec) => this.onUpdateSpecification(spec)}  {...this.props} {...this.state} />
                                </Tab>
                                <Tab eventKey="terms" title="Commercial Terms">
                                    <CommercialTermsStage onUpdate={(terms) => this.onUpdateTerms(terms)} />
                                </Tab>
                            </Tabs>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        saveInProgress || saveComplete ?
                            <React.Fragment>
                                <Button
                                    variant="primary"
                                    onClick={this.handleClose}
                                    disabled={saveInProgress}
                                >
                                    Close
                                </Button>

                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Button variant="secondary" onClick={this.handleClose}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={this.handleSave}
                                >
                                    Generate
                                </Button>

                            </React.Fragment>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default connect(
    state => state.quotes,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(QuoteDialog);