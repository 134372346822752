import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Users/actions';
import Loading from '../../Common/Loading';

class ResetPasswordDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: true,
            validated: false,
            userId: props.user.id,
            username: props.user.name,
            password: ""
        };
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleClose = () => {
        this.setState({ open: false });
    }

    handleSave = (e) => {
        e.preventDefault();
        if (this.state.password.length > 0) {
            this.props.resetUserPassword({
                userId: this.state.userId,
                password: this.state.password
            });
        } else {
            this.setState({ validated: true });
        }
    }

    render() {
        const { onComplete, saveInProgress, saveComplete } = this.props;
        const { open } = this.state;
        return (
            <Modal show={open}
                centered
                onExited={() => {
                    if (onComplete) {
                        this.props.resetUserForms();
                        onComplete();
                    }
                }}
            >
                <Modal.Header>
                    <Modal.Title>Reset Password for {this.state.username}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {saveComplete ?
                        <div style={{ textAlign: "center", padding: '3em' }}>
                            <h4>Password reset successfully.</h4>
                        </div>
                        : saveInProgress ?
                            <Loading loadMessage="Resetting password" />
                            :
                            <Form
                                onSubmit={this.handleSave}
                                noValidate
                                validated={this.state.validated}
                            >
                                <Form.Group>
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control required name="password" value={this.state.password} onChange={this.handleChange} type="password" placeholder="Password" />
                                    <Form.Control.Feedback type="invalid">
                                        Password is required.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form>}
                </Modal.Body>
                <Modal.Footer>
                    {
                        saveInProgress || saveComplete ?
                            <React.Fragment>
                                <Button
                                    variant="primary"
                                    onClick={this.handleClose}
                                    disabled={saveInProgress}
                                >
                                    Close
                                </Button>

                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Button variant="secondary" onClick={this.handleClose}>
                                    Cancel
                             </Button>
                                <Button
                                    variant="primary"
                                    onClick={this.handleSave}
                                >
                                    Reset
                              </Button>

                            </React.Fragment>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default connect(
    state => state.users,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ResetPasswordDialog);