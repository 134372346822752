import {
    SET_SCREEN_STATE,
    UPDATE_SCREEN_OPTIONS,
    UPDATE_WATER_OPTIONS,
    UPDATE_BEARING_OPTIONS,
    UPDATE_DECK,
    UPDATE_BREAKDOWN,
    ADD_DECK,
    ADD_BREAKDOWN,
    REMOVE_DECK,
    REMOVE_BREAKDOWN,
    BUILDING_SCREEN,
    SCREEN_BUILT,
    BUILD_FAILED,
    UPDATE_DECK_CUT_PROP,
    RESET_DECK_CUT_PROP,
    SCREEN_DETAIL_INVALID,
    RESET_SCREEN,
    SCREEN_REBUILT,
    ADD_SCREEN,
    DELETE_SCREEN
} from '../action-types';


export var getDefaultScreen = () => {
    return {
        id: window.crypto.getRandomValues(new Uint8Array(16))
            .map(value => value.toString(16).padStart(2, "0"))
            .join(""),
        itemName: "New Screen",
        screenInvalidated: false,
        formInvalid: false,
        buildFailed: false,
        changesMade: false,
        properties: {
            density: 1.6,
            efficiency: 95,
            feed: "0",
            wetCalcOption: 2,
            wetScreening: false,
            mosture: 0,
            shape: 100,
            angleType: "0",
            calcMethod: "1",
            screenType: "0",
            feedType: "0"
        },
        decks: [{
            cuts: [
                { Size: 0 },
                { Size: 0 },
                { Size: 0 }],
            cutInvalid: [false, false, false],
            material: 1
        }],
        breakdown: [{
            passing: '100',
            mm: '0'
        }],

        rebuild: false
    }
}

const initialState = {

    screens: [getDefaultScreen()],
    totalNumberOfScreens: 0

};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case SET_SCREEN_STATE:
            return {
                ...state,
                screens: action.screens,
                totalNumberOfScreens: action.screens.length
            };
        case UPDATE_SCREEN_OPTIONS:
            return {
                ...state,
                screens: action.screens//,
            };
        case UPDATE_WATER_OPTIONS:
            return {
                ...state,
                screens: action.screens,
                rebuild: action.rebuild
            };
        case UPDATE_BEARING_OPTIONS:
            return {
                ...state,
                screens: action.screens,
                rebuild: action.rebuild
            };
        case UPDATE_DECK:
            return {
                ...state,
                decks: action.decks,
                changesMade: true
            };
        case UPDATE_DECK_CUT_PROP:
            return {
                ...state,
                decks: action.decks,
                screenInvalidated: true
            };
        case RESET_DECK_CUT_PROP:
            return {
                ...state,
                decks: action.decks,
            };
        case UPDATE_BREAKDOWN:
            return {
                ...state,
                breakdown: action.breakdown,
                changesMade: true
            };
        case ADD_DECK:
            return {
                ...state,
                decks: action.decks,
                changesMade: true
            };
        case REMOVE_DECK:
            return {
                ...state,
                decks: action.decks,
                changesMade: true
            };
        case ADD_BREAKDOWN:
            return {
                ...state,
                breakdown: action.breakdown,
                changesMade: true
            };
        case REMOVE_BREAKDOWN:
            return {
                ...state,
                breakdown: action.breakdown,
                changesMade: true
            };
        case RESET_SCREEN:
            return {
                ...state,
                screens: action.screens,
            };
        case BUILDING_SCREEN:
            return {
                ...state,
                building: true,
                formInvalid: false,
                buildFailed: false
            };
        case SCREEN_REBUILT:
            return {
                ...state,
                calculatedScreen: action.calculatedScreen,
                properties: action.properties,
                screenInvalidated: false,
                formInvalid: false,
                buildFailed: false,
                building: false,
                rebuild: false,
                changesMade: false
            };
        case SCREEN_BUILT:
            return {
                ...state,
                screens: action.screens,
                // screenInvalidated: false,
                //formInvalid: false,
                //buildFailed: false,
                building: false,
                //rebuild: false,
                //changesMade: false
            };
        case SCREEN_DETAIL_INVALID:
            return {
                ...state,
                formInvalid: true,
                buildFailed: false
            };
        case BUILD_FAILED:
            console.error("[BUILD FAILED]", action.result);
            return {
                ...state,
                buildFailed: true,
                exception: action.result.exception,
                building: false
            };
        case ADD_SCREEN:
            return {
                ...state,
                screens: action.screens,
                totalNumberOfScreens: action.screens.length
            };
        case DELETE_SCREEN:
            return {
                ...state,
                screens: action.screens,
                totalNumberOfScreens: action.screens.length
            };

        default:
            return state;
    }
};
