import Api from '../../Api';
import { cloneDeep } from 'lodash';
import {
    GENERATING_QUOTE, RECEIVED_QUOTE_ITEMS,
    SAVING_ITEM_PRICE, SAVING_PRICEGROUP_DATA, UPDATE_QUOTE_OPTION, UPDATE_QUOTE_SCREEN,
    RECEIVED_QUOTE_TERMS
} from '../action-types';

export const actionCreators = {

    getQuoteWeight: () => async (dispatch, getState) => {
        return getState().screen.calculatedScreen;
    },


    generateQuote: (quoteModel) => async (dispatch, getState) => {



        quoteModel.json = JSON.stringify(quoteModel);
        quoteModel.screens = getState().screening.screens;

        dispatch({ type: GENERATING_QUOTE, inProgress: true, complete: false });

        await Api.Quote.generateQuote(quoteModel).then(async (response) => {

            if (response.ok) {

                let id = quoteModel.enquiry.enquiryId;

                await Api.Quote.downloadQuote(id).then(async (response) => {
                    response.blob().then(blob => {
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = quoteModel.enquiry.enquiryNumber + ".doc";

                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    });
                });

                await Api.Quote.downloadCosts(id).then(async (response) => {
                    response.blob().then(blob => {
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = quoteModel.enquiry.enquiryNumber + "_Costs.pdf";

                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })
                });

                dispatch({
                    type: GENERATING_QUOTE,
                    inProgress: false,
                    complete: true,
                    success: true
                });

                //this is now the previous quote to base future quotes on
                dispatch({
                    type: UPDATE_QUOTE_OPTION,
                    quoteExists: true,
                    config: quoteModel.json
                });

            } else {
                dispatch({ type: GENERATING_QUOTE, inProgress: false, complete: true, success: false });

                console.log("something went wrong.", response);

            }
        });
    },

    downloadQuote: (id, enquiryNumber) => async (dispatch, getState) => {

        dispatch({ type: GENERATING_QUOTE, inProgress: true, complete: false });

        let error = false;

        await Api.Quote.downloadQuote(id).then(async (response) => {

            if (response.ok) {

                response.blob().then(blob => {
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = enquiryNumber + ".doc";

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });

            } else { error = true; }
        });

        await Api.Quote.downloadCosts(id).then(async (response) => {

            if (response.ok) {

                response.blob().then(blob => {
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = enquiryNumber + "_Costs.pdf";

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });

            } else { error = true; }
        });

        if (!error) dispatch({ type: GENERATING_QUOTE, inProgress: false, complete: true, success: true });
        else {
            dispatch({ type: GENERATING_QUOTE, inProgress: false, complete: true, success: false });
            console.log("something went wrong.");
        }
    },

    doesQuoteExist: (id) => async (dispatch, getState) => {

        if (getState().quotes.canGenerateQuote === null) {
            await Api.Quote.existsForEnquiry(id).then(async (response) => {

                if (response.ok) {

                    var result = await response.json();

                    dispatch({
                        type: UPDATE_QUOTE_OPTION,
                        quoteExists: result.exists,
                        config: result.config
                    });

                } else {
                    dispatch({ type: UPDATE_QUOTE_OPTION, quoteExists: null });

                    console.log("something went wrong.");

                }
            });

        }
    },

    updateSelectedScreen: (screen) => async (dispatch, getState) => {
        dispatch({ type: UPDATE_QUOTE_SCREEN, selectedScreen: screen });
    },


    getQuoteItems: () => async (dispatch, getState) => {


        let screens = getState().screening.screens;
        let screen = screens[getState().quotes.selectedScreen];

        let weight = screen.calculatedScreen && screen.calculatedScreen.bearingLife.fabricatedWeight;
        let feed = screen.properties.feed;
        let lump = screen.breakdown[0].mm;

        await Api.Quote.getQuoteItems().then(async (response) => {
            var result = await response.json();

            dispatch({
                type: RECEIVED_QUOTE_ITEMS,
                quoteItems: result.quoteItems,
                fabricatedWeight: weight,
                initialFeed: feed,
                initialLump: lump,
            });
        });
    },

    updateQuoteItem: (itemModel) => async (dispatch) => {

        dispatch({ type: SAVING_ITEM_PRICE, inProgress: true, complete: false });
        await Api.Quote.updateQuoteItem(itemModel).then(async (response) => {
            var result = await response.json();

            if (result.ok) {
                //then....
            }
        });

        dispatch({ type: SAVING_ITEM_PRICE, inProgress: false, complete: true });
    },

    addPriceGroup: (groupModel) => async (dispatch) => {

        dispatch({ type: SAVING_PRICEGROUP_DATA, inProgress: true, complete: false });

        console.log(groupModel);

        await Api.Quote.addQuoteItem(groupModel).then(async (response) => {
            var result = await response.json();

            if (result.ok) {
                //then....
            }
        });

        dispatch({ type: SAVING_PRICEGROUP_DATA, inProgress: false, complete: true });

    },

    removePriceGroupItem: (model) => async (dispatch) => {
        dispatch({ type: SAVING_PRICEGROUP_DATA, inProgress: true, complete: false });

        await Api.Quote.removeQuoteItem(model).then(async (response) => {
            var result = await response.json();

            if (result.ok) {
                //then....
            }
        });

        dispatch({ type: SAVING_PRICEGROUP_DATA, inProgress: false, complete: true });

    },


    getQuoteTerms: () => async (dispatch, getState) => {
        await Api.Quote.getQuoteTerms().then(async (response) => {

            var result = await response.json();

            dispatch({ type: RECEIVED_QUOTE_TERMS, quoteTerms: result.quoteTerms })

        });
    },
    addQuoteTerm: (heading) => async (dispatch, getState) => {
        await Api.Quote.addQuoteTerm({ heading }).then(async (response) => {
            var result = await response.json();

            var updated = cloneDeep(getState().quotes.quoteTerms);

            updated.push(result);


            console.log("added", updated);


            dispatch({ type: RECEIVED_QUOTE_TERMS, quoteTerms: updated });


        });
    },
    updateQuoteTerm: (id, details) => async () => {
        await Api.Quote.updateQuoteTerm({ id, details }).then(async () => {

        });
    },
    removeQuoteTerm: (id) => async () => {
        await Api.Quote.removeQuoteTerm({ id }).then(async () => {

        });
    },

    resetPriceForms: () => async (dispatch) => {
        dispatch({ type: SAVING_PRICEGROUP_DATA, inProgress: false, complete: false });
    }

}