import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

function CutSummary(props) {

    return (
        <div className="property-table">
            <Row>
                <Col>
                    TPH Feed
            </Col>
                <Col>
                    {Number(props.feed).toFixed(3)}
                </Col>
            </Row>
            <Row>
                <Col>
                    Should Pass
            </Col>
                <Col>
                    {Number(props.tphShouldPass).toFixed(3)}
                </Col>
            </Row>
            <Row>
                <Col>
                    Will Pass
            </Col>
                <Col>
                    {Number(props.tphWillPass).toFixed(3)}
                </Col>
            </Row>
            <Row>
                <Col>
                    Misplaced
            </Col>
                <Col className="misplaced">
                    {Number(props.misplaced).toFixed(3)}
                </Col>
            </Row>
            <Row>
                <Col>&nbsp;</Col>
            </Row>                       
            <Row>
                <Col>Efficiency Of Removal</Col>
                <Col className={props.desiredEfficiency > parseInt(props.efficiencyOfRemoval) ? "cut-warning" : ""}>{parseInt(props.efficiencyOfRemoval)}%</Col>
            </Row>
            <Row>
                <Col>Contamination Of Overs</Col>
                <Col className={props.desiredEfficiency > parseInt(props.efficiencyOfRemoval) ? "cut-warning" : ""}>{parseInt(props.contaminationOfOvers)}%</Col>
            </Row>
            <Row style={{marginTop:"1em"}}>
                <Button onClick={props.adjustCutProperties} style={{width:"100%"}}>Adjust Cut</Button>
            </Row>           
        </div>);
}

export default CutSummary;