import Access from '../lib/Access';

export default {
    calculateScreen: (data) => fetch(`api/Screening/Calculate`, {        
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": 'Bearer ' + Access.getCachedToken()
        },
        body: JSON.stringify(data)
    }),
    getScreenMaterials: (data) => fetch(`api/Screening/Materials`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": 'Bearer ' + Access.getCachedToken()
        }
    })
};
