import React from 'react';
import ScreenSummaryTabs from './SummaryTabs';

function Screen(props) {
    const { decks, selectedDeck, selectedCut } = props;
    const percentageFactor = 100 / props.lengthFT;
    return (
        <div>
            <ScreenSummaryTabs {...props} />

            {decks &&
                <div style={{
                    display: 'flex',
                    marginTop: '1em',
                    marginBottom: '2em'
                }}
                >
                    <div className="feed-ui">
                        <svg height="40" width="30">
                            <polyline
                                points="30 35,0 20,0 0,30 0"
                                style={{
                                    stroke: '#444',
                                    strokeWidth: '2',
                                    fill: 'none'
                                }}
                            />
                        </svg>
                    </div>
                    <div className="deck-ui"
                        style={{
                            border: '2px solid #444',
                            flex: 1,
                            padding: '.25em'
                        }}
                    >
                        <div className="screen">
                            {decks.map(function (deck, i) {

                                let deckNumber = i;

                                return (
                                    <div className="deck" key={i}>
                                        {deck.cuts.map(function (cut, i) {
                                            return (
                                                <div className={cut.critical ? "cut-critical " : "cut-non-critical"}
                                                    style={{ width: percentageFactor * cut.length + "%" }}
                                                    key={i}
                                                >
                                                    <div
                                                        className={selectedDeck === deckNumber && selectedCut === i ? "cut selected-cut" : "cut"}
                                                        onClick={() => props.onCutSelected(deckNumber, i)} key={i}
                                                    >
                                                        {cut.cutSize}mm ({cut.length}ft/{cut.lengthMM}mm)
                                                    </div>
                                                </div>
                                            );
                                        })
                                        }
                                    </div>);
                            })
                            }
                        </div>
                    </div>
                </div>

            }

        </div>
    );
}

export default Screen;