import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Enquiries/actions';
import { Button, Container, Row, Col, OverlayTrigger, Tooltip, InputGroup, Form } from 'react-bootstrap';
import EnquiriesTable from './Table/EnquiriesTable';
import DeleteEnquiryDialog from './Dialog/DeleteEnquiryDialog';

class Enquiries extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            filter: "",
            allowDelete: this.props.userIsAdmin,
            selectedEnquiry: null
        };

        this.props.getEnquiries();
    }

    handleNew() {
        window.location = "/enquiry";
    }

    handleDeleteEnquiry = (enquiryId, enquiryNumber) => {
        this.setState({
            selectedEnquiry: {
                id: enquiryId,
                name: enquiryNumber
            }
        });
    }

    handleConfirmDelete = () => {
        this.props.deleteEnquiry(this.state.selectedEnquiry.id);
    }

    handleComplete = () => {
        this.setState({ selectedEnquiry: null });
    }

    filtered = () => {
        return this.props.enquiries ?
            this.props.enquiries.map(g => {
                return g.filter((e) => {
                    return (this.state.filter === "" || e.name.includes(this.state.filter));
                });
            })
            : null;
    }

    render() {
        return (
            <Container className="page-container">
                <Row>
                    <Col>
                        <h3>Enquiries</h3>
                    </Col>
                    <Col md="auto">
                        <InputGroup style={{ marginTop: '8px' }}>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Search</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control

                                value={this.state.filter}
                                onChange={(e) => this.setState({ filter: e.target.value })}
                            />
                        </InputGroup>
                    </Col>
                    <Col md="auto">
                        <OverlayTrigger
                            placement="left"
                            overlay={
                                <Tooltip >New Enquiry</Tooltip>
                            }
                        >
                            <Button
                                style={{ margin: '.5em' }}
                                variant="primary"
                                onClick={this.handleNew}
                            >
                                <i className="fas fa-plus" />
                            </Button>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <EnquiriesTable
                            enquiries={this.filtered()}
                            filter={this.state.filter}
                            allowDelete={this.state.allowDelete}
                            onDelete={this.handleDeleteEnquiry}
                        />
                    </Col>
                </Row>

                {this.state.selectedEnquiry &&
                    <DeleteEnquiryDialog
                        onComplete={this.handleComplete}
                        onConfirm={this.handleConfirmDelete}
                        enquiry={this.state.selectedEnquiry}
                    />
                }

            </Container>
        );
    }
}

export default connect(
    state => state.enquiries,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Enquiries);
